/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import io from "socket.io-client";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import Scrollbar from "@/shared/components/ScrollBar";
import { CurrentUserProps, ContactsProps } from "@/shared/prop-types/ChatProps";
import { useHistory } from 'react-router-dom';

import {
  colorAdditional,
  colorBackground,
  colorBackgroundBody,
  colorFieldsBorder,
  colorAccent,
  colorAccentHover,
  colorBubbleActive,
  colorText,
  colorBubble,
  colorHover,
} from "@/utils/palette";
import {
  paddingRight,
  paddingLeft,
  borderRight,
  left,
  right,
  marginRight,
  marginLeft,
} from "@/utils/directions";
import config from "../../../../config/config";
import ChatContact from "./ChatContact";
import ChatBubble from "./ChatBubble";
import ChatSearch from "./ChatSearch";
import ChatField from "./ChatField";
import { useParams } from 'react-router-dom';

import ChatTopbar from "./ChatTopbar";
// import socketIOClient from 'socket.io-client';
import {
  getChatMessages,
  getChat,
} from "../../../../utils/services/user.service";
import ChatWarningModal from "../../../../shared/components/ChatWarningModal/ChatWarningModal";
import { useLocation } from "react-router";
import { useChainContext } from "../../../context/context";

const Chat = ({ currentUser, contacts }) => {
  const { socket } = useChainContext()
  const scrollbarRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [currentMessages, setCurrentMessages] = useState(contacts[0].messages);
  const [currentChat, setCurrentChat] = useState(null);
  const [currentChat1, setCurrentChat1] = useState(contacts[0].user_id);
  const [openContacts, setOpenContacts] = useState(false);
  const [ChatId, setChatId] = useState();

  const [UserId, setUserId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchTotaluser, setSearchTotaluser] = useState(1);
  const [dataValue, setDatavalue] = useState(false);
  const [UserName, setUserName] = useState();
  const [ChatMessage, setChatMessage] = useState([]);
  const [chatData, setChatData] = useState([]);

  const [roomData, setroomData] = useState([]);
  const [NewMessage, setNewMessage] = useState("");
  const [getBlock, setBlock] = useState(false);
  const [getBlock1, setBlock1] = useState(false);
  const [messagesArray, setMessagesArray] = useState([]);
  const [uniqueMessageIds, setUniqueMessageIds] = useState(new Set());
  const [typingStatus, setTypingStatus] = useState('');
  const [flag, setFlag] = useState("")
  const [autoSearch, setAutoSearch] = useState("")
  const [readStatus, setReadStaatus] = useState(null)
  const [chatIsOpen ,setChatIsOpen]=useState(false)

  const userdata = JSON.parse(localStorage.getItem("user")).data.user.user_id

  const getStatusesWithIds = (chatData, userId) => {
    return chatData.map(room => {
      if (room.support_user_id === userId) {
        return { _id: room._id, status: room.support_read_status };
      } else if (room.user_id === userId) {
        return { _id: room._id, status: room.user_read_status };
      }
      return null;
    }).filter(status => status !== null);
  };

  useEffect(() => {
    const readStatuses = getStatusesWithIds(chatData, userdata);
    setReadStaatus(readStatuses)


  }, [chatData])
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");

  const paramName = searchParams.get("name");
  const queryParams = new URLSearchParams(window.location.search);
  const otcNameParam = queryParams.get('otcName');

  const currentTime = new Date();
  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const [selectedChat, setSelectedChat] = useState(false);

  const [ShowcurrentChat, setShowCurrentChat] = useState();
  const [currentView, setCurrentView] = useState(otcNameParam ? 'room' : 'chats');

  const [show, setShow] = useState(false);
  const [updateUnread, setUpdateUnread] = useState(false);

  const baseUrl = window.location.origin;
  let adminId = "";

  if (
    baseUrl === "http://localhost:3000" ||
    baseUrl === "https://app-dev.custodiy.com"
  ) {
    // adminId = "64d200a15a0ec71ba4278c9f";
    adminId = "64b5217b6a443499b2b0cc42";
  } else {
    // adminId = '64d4e16ed3ff9e37bc1f1c62';
    adminId = "62d2ab87e9344df9aa35d77a";
  }
  // const socket = io(config.baseURL);
  const NoImg = `${process.env.PUBLIC_URL}/img/33.png`;

  useEffect(() => {
    if (otcNameParam) {
      setCurrentView('room')
    }
  }, [])
  const getChatData = () => {
    const payload = {
      type: "all",
      limit: 10,
      page_number: 1,
      user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    getChat(payload)
      .then((data) => {
        // console.log('getChat data >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
        const dta = data.data.chats;

        const privateChats = dta.filter(
          (chat) => chat.type === "private" || chat.type === "user"
        );
        const roomChats = dta.filter((chat) => chat.type === "room" || chat.type === "otc-room");
        const otcRoomChats = dta.filter((chat) => chat.type === "otc-room");

        setChatData(privateChats);
        setroomData(roomChats);
        if (otcRoomChats.length > 0) {

          setSelectedChat(true);
        } else {

          setSelectedChat(false);
        }
        setBlock1(false);
        setBlock(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data:", error);
      });
  };
  useEffect(() => {
    getChatData();
  }, [openContacts]);

  const getChatDataNew = () => {
    const payload = {
      type: "all",
      limit: 10,
      page_number: 1,
      user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    getChat(payload)
      .then((data) => {
        const dta = data.data.chats;
        // console.log('getChat getChatDataNew >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', dta);
        const privateChats = dta.filter(
          (chat) => chat.type === "private" || chat.type === "user"
        );
        const roomChats = dta.filter((chat) => chat.type === "room");
        // console.log('privateChats', privateChats);
        setChatData(privateChats);
        setroomData(roomChats);
        setSelectedChat(false);
        setBlock1(false);
        setBlock(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data: ", error);
      });
  };

  const getChatDataNewUNblock = () => {
    const payload = {
      type: "all",
      limit: 10,
      page_number: 1,
      user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    getChat(payload)
      .then((data) => {
        const dta = data.data.chats;
        // console.log('getChat getChatDataNew >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', dta);
        const privateChats = dta.filter(
          (chat) => chat.type === "private" || chat.type === "user"
        );
        const roomChats = dta.filter((chat) => chat.type === "room");
        // console.log('privateChats', privateChats);
        setChatData(privateChats);
        setroomData(roomChats);
        setSelectedChat(true);
        setBlock1(false);
        setBlock(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data: ", error);
      });
  };

  const getChatDataNewblock = () => {
    const payload = {
      type: "all",
      limit: 10,
      page_number: 1,
      user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    getChat(payload)
      .then((data) => {
        const dta = data.data.chats;
        // console.log('getChat getChatDataNew >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', dta);
        const privateChats = dta.filter(
          (chat) => chat.type === "private" || chat.type === "user"
        );
        const roomChats = dta.filter((chat) => chat.type === "room");
        // console.log('privateChats', privateChats);
        setChatData(privateChats);
        setroomData(roomChats);
        setSelectedChat(false);
        setBlock1(false);
        setBlock(true);
      })
      .catch((error) => {
        console.error("Error fetching chat data: ", error);
      });
  };

  useEffect(() => {
    getChatData();

    if (!socket) {
      return false
    }
  
    socket.on("searchUser_response", (response) => {

      if (searchTotaluser === 0) {
        setSearchResult([]);
      } else {
        setChatData(response);
      }

    });
    socket.on("message", (newMessage) => {
      if (!uniqueMessageIds.has(newMessage._id)) {
        setMessagesArray((prevMessages) => [...prevMessages, newMessage]);
        setUniqueMessageIds((prevUniqueIds) =>
          new Set(prevUniqueIds).add(newMessage._id)
          
        );
        getChatData()
      }
    });

    let typingTimeout;
    socket.on("typing", (data) => {
      clearTimeout(typingTimeout);
      setFlag(data.status);
      typingTimeout = setTimeout(() => {
        setFlag("");
      }, 2000); // 2000 milliseconds = 2 seconds
    });
    socket.on("disconnect", () => {
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    // const interval = setInterval(() => {
    socket.on("new_chat", (response) => {
      // console.log('New Chat Data:', response);
      if (Array.isArray(response)) {
        response.forEach((newObj) => {
          if (newObj.type === "room") {
            if (!roomData.some((roomObj) => roomObj._id === newObj._id)) {
              setroomData((prevroomData) => [...prevroomData, newObj]);
            }
          } else if (newObj.type === "chat") {
            if (!chatData.some((chatObj) => chatObj._id === newObj._id)) {
              setChatData((prevChatData) => [...prevChatData, newObj]);
            }
          } else {

          }
        });
      } else {

      }
    }
    );
  }, [socket]);

  const sendMessage = (message) => {

    setNewMessage(message);
    if (currentChat1.type === "room") {
      socket.emit(
        "sendGroupMessage",
        {
          room_id: ChatId,
          sender_id: JSON.parse(localStorage.getItem("user")).data.user
            .user_id,
          message,
        },
        (response) => {
          // console.log("Group Message sent, server response:", response);
        }
      );
    } else {
      socket.emit(
        "sendMessage",
        {
          chat_id: ChatId,
          sender_id: JSON.parse(localStorage.getItem("user")).data.user
            .user_id,
          message,
        },
        (response) => {
          // console.log("Message sent, server response:", response);
        }
      );
    }
  };

  useEffect(() => {
    setChatMessage([]);
    setSelectedChat(false);
    setMessagesArray([]);
  }, [currentView]);

  useEffect(() => {
    if (NewMessage.length > 0) {
      const updatedMessage = { message: NewMessage };
      setMessagesArray((prevMessages) => [...prevMessages, updatedMessage]);
    }
  }, [NewMessage]);

  const fetchChats = async (id) => {
    // setIsLoading(true);
    getChat()
    try {
      const requestData = {
        chat_id: id,
      };
      const response = await getChatMessages(requestData);
      if (response.data.chat_reply) {
        const flashProductsData = response.data.chat_reply;
        setChatMessage(flashProductsData);
        // setIsLoading(false);
        setMessagesArray([]);
      } else {
        console.error('Error fetching tickets:', response.statusText);
      }
    } catch (error) {
      setChatMessage([]);
      // setIsLoading(false);
      console.error('Error fetching tickets:', error);
    }
  };

  const onOpenChat = (item) => {
    if (!otcNameParam) {
      history.push('/livechat')
    }
    setChatIsOpen(true)
    setCurrentChat(item?._id);
    setCurrentChat1(item);
    setShowCurrentChat(item);
    setChatId(item?._id);
    fetchChats(item?._id);
    setUserId(item?.user_id);
    setUserName(item?.user_detail?.[0]?.name || item?.name || item?.subject);
    // Assuming ShowcurrentChat is defined and accessible here
    if (item?.chat_status?.length === 0) {
      socket.emit(
        "startChat",
        {
          subject: "Start New One to One Chat",
          user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
          user_id_2: item._id,
          type: "user",
        },
        (response) => {

        }
      );
      socket.on("get_chat_id", (response) => {
        // console.log('Get Chat Id Response:', response);
        if (response) {

          // setOnlineStatus(true);
          // console.log('get_chat_id  1 >>>>>>>> Start New Chat with Support @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', response);
          setCurrentChat(response?._id);
          setCurrentChat1(response);
          setShowCurrentChat(response);
          setChatId(response._id);
          setChatData([response])
          fetchChats(response._id);
          setUserId(response.user_id);
          setUserName(
            response.user_detail ? response.user_detail[0]?.name : response?.name
          );
        }
        // setChatId(response._id);
      });
    }
  };
  const onOpenInbox = (item) => {
    setCurrentChat(item?._id);
    setCurrentChat1(item);
    setShowCurrentChat(item);
    setChatId(item?._id);
    fetchChats(item?._id);
    setUserId(item?.user_id);
    setUserName(item?.user_detail ? item?.user_detail[0]?.name : item?.name);
    // Assuming ShowcurrentChat is defined and accessible here
    if (item?.chat_status) {
      socket.emit(
        "startChat",
        {
          subject: "Start New One to One Chat",
          user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
          user_id_2: item._id,
          type: "user",
        },
        (response) => {

        }
      );
      const res = getChatData();

      socket.on("get_chat_id", (response) => {
        if (response) {
          setCurrentChat(response?._id);
          setCurrentChat1(response);
          setShowCurrentChat(response);
          setChatId(response._id);
          setChatData([response])
          fetchChats(response._id);
          setUserId(response.user_id);
          setUserName(
            response.user_detail ? response.user_detail[0]?.name : response?.name
          );
        }
        // setChatId(response._id);
      });
    }
  };

  const StartNewChat = () => {
    history.push('/roomchat')
    socket.emit(
      "startChat",
      {
        subject: "Start New Chat with Support",
        user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
        // premai
        // user_id_2: '64d4e16ed3ff9e37bc1f1c62',
        // dev.cus
        // user_id_2: '64d200a15a0ec71ba4278c9f',
        user_id_2: adminId,
        type: "private",
      },
      (response) => {
        // console.log(
        //   "Start New chat response >>>>>>>>>>>>>>>>>>>>>>>>>>",
        //   response
        // );
      }
    );
    socket.on("get_chat_id", (response) => {
      if (response) {

        // setOnlineStatus(true);
        // console.log('get_chat_id  1 >>>>>>>> Start New Chat with Support @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', response);
        setCurrentChat(response?._id);
        setCurrentChat1(response);
        setShowCurrentChat(response);
        setChatId(response._id);
        fetchChats(response._id);
        setChatData([response])
        setUserId(response.user_id);
        setUserName(
          response.user_detail ? response.user_detail[0]?.name : response?.name
        );
      }
      // setChatId(response._id);
    });
    fetchChats(adminId);
  };

  useEffect(() => {
    // console.log('ShowcurrentChat', ShowcurrentChat);

    if (
      ShowcurrentChat &&
      ShowcurrentChat.block_user &&
      ShowcurrentChat.block_user[0]
    ) {
      if (
        ShowcurrentChat.block_user[0].blocker_user_id ===
        JSON.parse(localStorage.getItem("user")).data.user.user_id
      ) {
        setBlock(true);
        setBlock1(false);
      }
      if (
        ShowcurrentChat.block_user[0].user_id ===
        JSON.parse(localStorage.getItem("user")).data.user.user_id
      ) {
        setBlock(false);
        setBlock1(true);
      }
    } else {
      setBlock(false);
      setBlock1(false);
    }
    if (
      (ShowcurrentChat && ShowcurrentChat.status) ||
      ShowcurrentChat?.chat_status[0]?.status === "open"
    ) {
      setSelectedChat(true);
    } else if (ShowcurrentChat?.chat_status?.length === 0) {
      setSelectedChat(true);
    }
    else {

      setSelectedChat(false);
    }
  }, [ShowcurrentChat]);

  const onOpenContacts = () => {
    setOpenContacts((state) => !state);
  };



  const searchUsersSoket = (value) => {

    if (!socket) {
      return false
    }
  
    socket.emit(
      "searchUsers",
      {
        name: value,
        user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
      },
      (response) => {
        // Handle the response from the server, if needed
        // console.log("searchUsers", response);
      }
    );

    socket.on("searchUser_response", (response) => {
      if (searchTotaluser === 0) {
        setSearchResult([]);
      } else {
        setChatData(response);
      }

    });
  };
  const searchUsersSokets = (value) => {
    if (autoSearch) {
      return
    }
    if (!socket) {
      return false
    }
    setAutoSearch(value)
    socket.emit(
      "searchUsers",
      {
        name: value,
        user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
      },
      (response) => {
        // Handle the response from the server, if needed
        // console.log("searchUsers", response);
      }
    );
    socket.on("searchUser_response", (response) => {
      if (response) {
        setChatData(response);
      }

    });
  };

  const handleSearchChange = (value) => {
    if (value.length === 0) {
      setSearchTotaluser(0);

      setChatData([]);
      getChatData();
    }
    if (currentView === "chats") {
      if (value.length !== 0) {
        searchUsersSoket(value);
      }
    } else {

    }
    setSearchValue(value);
  };



  const displayData = currentView === "chats" ? chatData : roomData;

  useEffect(() => {

    if (otcNameParam) {
      setCurrentView('room');
      onOpenContacts()

      const filteredData = displayData.filter(item => item.subject === otcNameParam);

      if (filteredData) {
        setCurrentView('room');
        setSelectedChat(true);
        onOpenChat(filteredData[0])
      }
    }
  }, [displayData]);

  const { user } = JSON.parse(localStorage.getItem("user")).data;


  useEffect(() => {
    if (userId) {
      const userObject = displayData.find(obj => obj.support_user_id === userId);
      if (userObject) {

        onOpenInbox(userObject);
        getChatData()
      }
      else {

        searchUsersSokets(paramName)
        onOpenInbox(displayData[0]);
        getChatData()
      }
    }
  }, [userId, displayData]);

  useEffect(() => {
    if (user.chat_status !== "enabled") {
      setShow(true);
    }
  }, []);

  useEffect(() => {

    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, scrollbarRef.current.contentEl.offsetHeight);
    }
  }, [ChatMessage, messagesArray]);

  return (
    <ChatContainer
      open={openContacts}
      onClick={openContacts ? onOpenContacts : null}
      role="presentation"
    >
      {show && (
        <div>
          <ChatWarningModal open={show} />
        </div>
      )}

      <ChatContactList open={openContacts}>
        <Tabs
          defaultActiveKey={currentView}
          id="uncontrolled-tab-example"
          className="mb-3 justify-content-center"
          onSelect={(k) => setCurrentView(k)}
        >
          <Tab eventKey="chats" title="&nbsp;User Chat&nbsp;">
            <p style={{ textTransform: "capitalize" }} className="mb-2" />
          </Tab>
          <Tab eventKey="room" title="Live Chat">
            <p style={{ textTransform: "capitalize" }} className="mb-2" />
          </Tab>
        </Tabs>
        <ChatSearch
          onSearchChange={handleSearchChange}
          currentView={currentView}
          onClick={onOpenContacts}
        />
        <ChatContacts style={{ overflow: "hidden", paddingBottom: '60px' }}>
          <ChatContactsScroll alwaysShowTracks style={{ overflow: "scroll" }}>
            {currentView === "chats" && (
              <ChatContactButton type="button" onClick={StartNewChat}>
                <ChatContactWrap>
                  <ChatContactAvatar>
                    <img src={NoImg} alt="Group Chat" />
                    <OnlineIndicator />
                  </ChatContactAvatar>
                  <ChatContactPreview>
                    <ChatContactName>
                      Start new chat with Support
                    </ChatContactName>
                  </ChatContactPreview>
                </ChatContactWrap>
              </ChatContactButton>
            )}
            {displayData && displayData?.length > 0 ? (
              displayData?.map((item) => (
                <ChatContactButton
                  key={item._id}
                  type="button"
                  onClick={(e) => onOpenChat(item, e)}
                >
                  <ChatContact
                    active={item._id === currentChat}
                    contact={item}
                    readStatus={readStatus}
                    chatIsOpen={chatIsOpen}
                  />
                </ChatContactButton>
              ))
            ) : (
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                No {currentView === "chats" ? "chat" : "room"} available.
              </p>
            )}
          </ChatContactsScroll>
        </ChatContacts>
      </ChatContactList>

      {currentChat === null ? (
        <ChatDialog>
          <ChatTopbar ChatId={ChatId} onClick={onOpenContacts} typingStatus={flag} />
          <ChatEmptyMessages>
            <p>Select a chat to start messaging</p>
          </ChatEmptyMessages>
          <div className="flex w-[30%]"></div>
        </ChatDialog>
      ) : (
        <ChatDialog>
          <ChatTopbar
            contact={
              displayData &&
              displayData?.find((item) => item?._id === currentChat)
            }
            ChatId={ChatId}
            onClick={onOpenContacts}
            getChatDataNew={getChatDataNew}
            getChatDataNewblock={getChatDataNewblock}
            getChatDataNewUNblock={getChatDataNewUNblock}
            typingStatus={flag}
          />
          <ChatScroll alwaysShowTracks ref={scrollbarRef} >
            <ChatMessagesWrap  >
              {currentMessages === null ||
                !Array.isArray(currentMessages) ||
                currentMessages.length === 0 ? (
                <ChatMessages>
                  <ChatEmptyMessages>
                    <p>No Messages</p>
                  </ChatEmptyMessages>
                </ChatMessages>
              ) : (
                <ChatMessages>
                  {(ChatMessage && ChatMessage.length > 0) ||
                    (messagesArray && messagesArray.length > 0) ? (
                    ChatMessage.map((item) => (
                      <ChatBubble
                        key={item._id}
                        message={item}
                        date={item}
                        active={item.chat_id === currentUser._id}
                      />
                    ))
                  ) : (
                    <ChatEmptyMessages>
                      <p>No messages</p>
                    </ChatEmptyMessages>
                  )}

                  {messagesArray.map((message, index) => (
                    <ChatBubbleMessageWrap
                      key={index}
                      style={
                        message.type === "room" || message.type === "private"
                          ? {
                            marginright: "auto",
                            borderRadius: "0 15px 15px 0px",
                            padding: "15px 15px 10px 12px",
                            paddingTop: "7px",
                            maxWidth: "fit-content",
                            marginBottom: "20px",
                            paddingBottom: "4px",
                          }
                          : {
                            marginBottom: "20px",
                            background: "rgba(45,49,57,1)",
                            maxWidth: "fit-content",
                            marginLeft: "auto",
                            borderRadius: "15px 0px 0px 15px",
                            padding: "10px 10px 5px 15px",
                            paddingTop: "7px",
                            paddingBottom: "4px",
                          }
                      }
                    >
                      {message.type === "room" ||
                        message.type === "private" ? (
                        <ChatBubbleContactName
                          style={{ textTransform: "capitalize" }}
                        >
                          {message?.user?.name}
                        </ChatBubbleContactName>
                      ) : (
                        <ChatBubbleContactName
                          style={{ textTransform: "capitalize" }}
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </ChatBubbleContactName>
                      )}
                      {message.type === "room" ||
                        message.type === "private" ? (
                        <ChatBubbleMessage style={{ margin: "0 0 5px 0" }}>
                          {message.message &&
                            typeof message.message === "string"
                            ? message.message
                            : null}
                        </ChatBubbleMessage>
                      ) : (
                        <ChatBubbleMessage style={{ margin: "0 0 5px 0" }}>
                          {message.message &&
                            typeof message.message === "string"
                            ? message.message
                            : null}
                        </ChatBubbleMessage>
                      )}
                      {message.Files && message.Files.length > 0 && (
                        <div>
                          {message.Files.map((file, index) => (
                            <a
                              key={index}
                              href={file}
                              target="_blank"
                              className="ml-4"
                              rel="noopener noreferrer"
                              download
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                size="1x"
                                className="my-icon"
                              />
                            </a>
                          ))}
                        </div>
                      )}
                      <ChatBubbleDate>{formattedTime}</ChatBubbleDate>
                    </ChatBubbleMessageWrap>
                  ))}
                </ChatMessages>
              )}
            </ChatMessagesWrap>
          </ChatScroll>
          {/* {selectedChat && !getBlock && !getBlock1 && ( */}
          <ChatField onSendMessage={sendMessage} ChatId={ChatId} />
          {/* )} */}
          {getBlock && (
            <div
              className="text-center py-2 px-5"
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                background: "rgb(45, 49, 57)",
              }}
            >
              <h4 style={{ color: "#dc3545" }}>
                {" "}
                <i
                  className="mx-2 fa fa-ban"
                  style={{
                    fontSize: "18px",
                    color: "#dc3545",
                  }}
                />
                You blocked this user
              </h4>
            </div>
          )}
          {getBlock1 && (
            <div
              className="text-center py-2 px-5"
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                background: "rgb(45, 49, 57)",
              }}
            >
              <h4 style={{ color: "#dc3545" }}>
                {" "}
                <i
                  className="mx-2 fa fa-ban"
                  style={{
                    fontSize: "18px",
                    color: "#dc3545",
                  }}
                />
                This user blocked you
              </h4>
            </div>
          )}
          {/* <ChatField onSendMessage={sendMessage} /> */}
        </ChatDialog>
      )}
    </ChatContainer>
  );
};

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  contacts: ContactsProps.isRequired,
};

export default Chat;

// region STYLES
const ModalStyle = styled.div`
  z-index: 22222;
  position: relative;
`;
const ChatBubbleMessageWrap = styled.div`
  max-width: 670px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 15px 15px 0px;

  ${paddingRight}: 40px;
  ${paddingLeft}: 20px;
  background-color: ${colorBubble};
  ${(props) => props.file && "padding: 10px 20px 20px 20px"};

  img {
    max-width: 215px;
  }
`;

const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorAccent};
  ${paddingRight}: 50px;
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: ${colorText};
`;

const ChatBubbleDate = styled.p`
  color: ${colorAdditional};
  font-size: 10px;
  margin: 0;
  text-align: end;
  ${right}: 36px;

  @media screen and (min-width: 1366px) {
    ${right}: 0;
  }
`;

const ChatContactAvatar = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  ${marginRight}: 10px;
  ${marginLeft}: 15px;

  img {
    height: 100%;
  }
`;

const OnlineIndicator = styled.div`
  position: absolute;
  // bottom: 0;
  // right: 0;
  width: 12px;
  height: 12px;
  background-color: green; // You can use any color you prefer
  border-radius: 50%;
  border: 2px solid #fff;
`;

const ChatContactWrap = styled.div`
  position: relative; /* Added relative positioning */
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 1px solid ${colorFieldsBorder};

  &:hover {
    background-color: ${colorHover};
  }

  ${(props) =>
    props.active &&
    `
    background-color: ${colorHover(props)};

    &:hover {
      background-color: ${colorHover(props)};
    }
  `}
`;

const ChatContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: ${colorAccent};
`;

const ChatContactPreview = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: calc(100% - 80px);
  ${marginRight}: 20px;
  ${marginLeft}: 0;
`;

const ChatContainer = styled.div`
  height: calc(100vh - 185px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: ${colorBackground};

  @media screen and (max-width: 1023px) {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      ${right}: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }

    ${(props) =>
    props.open &&
    `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatContactList = styled.div`
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  background-color: ${colorBackground};
  ${borderRight}: 1px solid ${colorFieldsBorder};

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    ${(props) =>
    props.open &&
    `
      transform: translateX(0);
      ${borderRight(props)}: none;
    `}
  }
`;

const ChatContacts = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
`;

const ChatContactButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  text-align: ${left};
`;

const ChatDialog = styled.div`
  width: calc(100% - 335px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  width: 100%;
  display: flex;
`;

const ChatMessages = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: ${colorAdditional};
    background-color: ${colorBackgroundBody};
  }
`;

const ChatScroll = styled(Scrollbar)`
  text-align: ${left};

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const ChatContactsScroll = styled(Scrollbar)`
  height: 100%;

  .scroll-content {
    &,
    & > div {
      height: 100%;
    }
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;
// endregion