/* eslint-disable consistent-return */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Ring } from "@uiball/loaders";
import { Button } from "../../../../shared/components/Button";
import { Col, Container, Row } from "react-bootstrap";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEnvelope,
  faUser,
  faMap,
} from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import EyeIcon from "mdi-react/EyeIcon";
import Panel from "@/shared/components/Panel";
import { Table } from "@/shared/components/TableElements";
import { getWeekChartData } from "@/utils/helpers";
import ErrorAlert from "@/shared/components/ErrorAlert";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../shared/components/CustomSelect";
import TopTenRow from "./TopTenRow";
import {
  // createMarketPlace,
  getPublicTransactions,
  getdetailSearch,
  getWalletActivities,
  walletSendReceiveActivities,
  getAllTokens,
  getAccountHistory,
  getAllWallets,
} from "../../../../utils/services/user.service";
import ReactTableBase from "../../../../shared/components/table/ReactTableBase";
import moment from "moment";
import { useChainContext } from "../../../context/context";

const TopActivies = () => {
  const { topTen, cryptoHistory, isHistoryLoading, isTopTenLoading } =
    useSelector((state) => ({
      topTen: state.topTen.data,
      cryptoHistory: state.cryptoHistory.data.cryptoHistory,
      errorTopTen: state.topTen.error,
      errorHistory: state.cryptoHistory.error,
      isHistoryLoading: state.cryptoHistory.isFetching,
      isTopTenLoading: state.topTen.isFetching,
    }));
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [getTransactions, setTransactions] = useState([]);
  const [getTransactions1, setTransactions1] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [selectedHistoryOption, setSelectedHistoryOption] = useState("virtual");
  const [selectedOperation, setSelectedOperation] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [selectedApprover, setSelectedApprover] = useState("");
  const [isSearchName, setIsSearchName] = useState(false);
  const [isSearchDate, setIsSearchDate] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [isSearchContract, setIsSearchContract] = useState(false);
  const [isSearchApprover, setIsSearchApprover] = useState(false);
  const [isSearchSymbol, setIsSearchSymbol] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [isContracts, setIsContracts] = useState(false);
  const [isMarketplaceContracts, setIsMarketplaceContracts] = useState(false);
  const [isSafebox, setIsSafebox] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { reFetchActivities } = useChainContext()

  const [allTokens, setAllTokens] = useState([]);

  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL =
    "https://testnet.bscscan.com/tx/";
  const openProductDetails = async (id) => {

    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getdetailSearch(id);
      if (response.data.status === 200) {
        const Data = response.data.result[0];
        // console.log('getdetailSearch >>>>>>>>>>', Data);
        setSelectedOrder(Data);
        setIsLoading(false);
      } else {
        console.error("Error fetching getdetailSearch:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching getdetailSearch:", error);
    }
    setModalIsOpen(true);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const fetchAllTokens = async () => {
    const payload = {
      chain_id: "",
      limit: 100,
      page_number: 1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
  };
  useEffect(() => {
    // Filter and map the allTokens array
    const options = allTokens.map(token => ({
      value: token.symbol,
      label: token.symbol
    }));

    // Update the state with the new dropdown options
    setDropdownOptions(options);
  }, [allTokens]);
  useEffect(() => {
    // fetchAllTokens();
    getWalletDetail()
  }, []);
  const [strigaWalletDetail, setStrigaWalletDetail] = useState(null)
  const getWalletDetail = async () => {
    setIsLoading(true)
    try {
      const res = await getAllWallets()
      setStrigaWalletDetail(res?.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (strigaWalletDetail && strigaWalletDetail.wallets && selectedHistoryOption != "virtual") {
      const firstWallet = strigaWalletDetail.wallets[0]; // Accessing the first wallet object
      if (firstWallet && firstWallet.accounts) {
        const accounts = Object.values(firstWallet.accounts);
        const options = accounts?.map(account => ({
          value: account.currency,
          label: account.currency,

        }));

        setAllTokens(options);
      }
    } else {
      fetchAllTokens()
    }
  }, [selectedHistoryOption]);






  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };

  const header = [
    { accessor: "date", Header: "Date" },
    { accessor: "name", Header: "Name" },
    // { accessor: 'contract', Header: 'Contract' },
    { accessor: "hash", Header: "Hash", disableGlobalFilter: true },
    { accessor: "amount", Header: "Amount" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "operation", Header: "Operation" },
    { accessor: "status", Header: "Status" },
    { accessor: "approver", Header: "Approver" },
    {
      accessor: "action",
      Header: "Action",
      disableGlobalFilter: true,
      width: 80,
    },
  ];

  const resetData = (order) => {
    setSelectedDate("");
    setSelectedOrderStatus("");
    setSelectedSymbol("");
    setSelectedName("");
    setSelectedAmount("");
    setSelectedApprover("");
    setSelectedOperation("");
    setTransactions(getTransactions1);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    const formattedDate = formatDate(newDate);
    let completedTransactions;
    completedTransactions = getTransactions1.filter(
      (transaction) => transaction.Date === formattedDate
    );
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      }
    }
    setTransactions(completedTransactions);
    setSelectedDate(newDate);
  };

  const handleOrderStatusChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(
      (transaction) => transaction.status === value.value
    );
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      }
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedOrderStatus(value.value);
    setTransactions(completedTransactions);
  };

  const handleOrderOperationChange = (value) => {
    let completedTransactions;
    if (value.value === "Send") {
      completedTransactions = getTransactions1.filter(
        (transaction) =>
          transaction.Transfer ===
          JSON.parse(localStorage.getItem("user")).data.user.address
      );
      setTransactions(completedTransactions);
    } else {
      completedTransactions = getTransactions1.filter(
        (transaction) =>
          transaction.Transfer !==
          JSON.parse(localStorage.getItem("user")).data.user.address
      );
      setTransactions(completedTransactions);
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedOperation(value.value);
    setTransactions(completedTransactions);
  };

  const handleSymbolChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(
      (transaction) => transaction.Symbol === value.value
    );
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedSymbol(value.value);
    setTransactions(completedTransactions);
  };

  const handleNameChange = (value) => {
    const partialValue = value.target.value || 'Bank Transection';
    let completedTransactions;
    completedTransactions = getTransactions1.filter((transaction) =>
      transaction.Name || 'Bank Transection'.toLowerCase().includes(partialValue.toLowerCase())
    );
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      }
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedName(value.target.value);
  };

  const handleAmountChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter((transaction) =>
      transaction.Amount || transaction.balanceAfter?.amount.toLowerCase().includes(partialValue.toLowerCase())
    );
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
        setTransactions(completedTransactions);
      }
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedAmount(value.target.value);
  };

  const handleApproverChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    if (partialValue.length === 0) {
      completedTransactions = getTransactions1;
    } else {
      completedTransactions = getTransactions1.filter((transaction) =>
        transaction.approver?.name
          .toLowerCase()
          .includes(partialValue.toLowerCase())
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase())
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.Symbol === selectedSymbol
      );
    }
    if (selectedOrderStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedOrderStatus
      );
    }
    if (selectedOperation !== "") {
      if (selectedOperation === "Send") {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer ===
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
      } else {
        completedTransactions = completedTransactions.filter(
          (transaction) =>
            transaction.Transfer !==
            JSON.parse(localStorage.getItem("user")).data.user.address
        );
      }
    }
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = transactionDate.getDate().toString().padStart(2, "0");
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedApprover(value.target.value);
  };

  const handleSearchFilterChange = (value) => {
    if (value.value === "Date") {
      setIsSearchName(false);
      setIsSearchDate(true);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Contract") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(true);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Approver") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(true);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Name") {
      setIsSearchName(true);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Status") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(true);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Symbol") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(true);
      setIsSearchAmount(false);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Amount") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(true);
      setIsSearchOperation(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "Operation") {
      setIsSearchName(false);
      setIsSearchDate(false);
      setIsSearchStatus(false);
      setIsSearchContract(false);
      setIsSearchApprover(false);
      setIsSearchSymbol(false);
      setIsSearchAmount(false);
      setIsSearchOperation(true);
      setSelectedSearch(value.value);
    }
  };

  const optionOrderStatus = [
    { value: "Waiting", label: "Waiting" },
    { value: "Completed", label: "Completed" },
    { value: "Failed", label: "Failed" },
    { value: "pending", label: "pending" },
    { value: "waiting_confirmation", label: "Waiting Confirmation" },
    { value: "pending_withdraw", label: "Pending Withdraw" },
];

  const optionOperations = [
    { value: "Send", label: "Send" },
    { value: "Receive", label: "Receive" },
  ];
  const historyOption = [
    { label: "Bank History", value: "bank" },
    { label: "Virtual History", value: "virtual" },
    { label: "onChain History", value: "onchain" },
    // { label: "Request Withdraw", value: "request_withdraw" },
  ];

  const searchFilters = [
    { value: "Amount", label: "Amount" },
    { value: "Approver", label: "Approver" },
    { value: "Contract", label: "Contract" },
    { value: "Date", label: "Date" },
    { value: "Name", label: "Name" },
    { value: "Operation", label: "Operation" },
    { value: "Status", label: "Status" },
    { value: "Symbol", label: "Symbol" },
  ];

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      default:
        return "#70bbfd";
    }
  };

  const data = [];
  getTransactions.map((item) =>
    data.push({
      date: item.Date || moment(item?.timestamp).subtract(10, 'days').calendar(),
      name: item.Name || 'Bank Transaction',
      //  contract: item.type === 'contract' ? `Contract@ ${item.Name.substring(0, 12)}...` : 'Direct Transaction',
      hash: (
        <button className="d-flex bg-transparent" style={{
          border: 'none'
        }}
          disabled={!item.hash}

        >
          <HashLink
            role="link"
            onClick={() => {
              let baseURL;
              if (["transaction", "external_wallet", "contract"].includes(item.type) && ["ETH", "USDT_ERC20", "USDT (ERC20)", "PAX", "GBPT", "EUROC"].includes(item.Symbol)) {
                baseURL = "https://etherscan.io/tx/";
              } else if (["transaction", "external_wallet", "contract"].includes(item.type) && ["BNB", "CTY", "FTY", "OPY", "BUSD", "USDT", "MET$", "META"].includes(item.Symbol)) {
                baseURL = "https://bscscan.com/tx/";
              } else if (["transaction", "external_wallet", "contract"].includes(item.type) && ["SOLANA", "LTX", "WEN", "SoLpy"].includes(item.Symbol)) {
                baseURL = "https://explorer.solana.com/tx/";
              } else if (["contract_test", "external_wallet_test"].includes(item.type)) {
              } else if (["transaction", "external_wallet", "contract"].includes(item.type) && ["TRON", "USDT (TRC20)"].includes(item.Symbol)) {
                baseURL = "https://tronscan.io/#/transaction/";
              } else if (["contract_test", "external_wallet_test"].includes(item.type)) {
                baseURL = "https://testnet.bscscan.com/tx/";
              }


              else {
                return null; // Handle the case where item.type or item.Symbol doesn't match any condition
              }

              openInNewTab(`${baseURL}${item.hash}`);
            }}

          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </HashLink>
        </button>
      ),
      amount: item.Amount || item?.balanceAfter?.amount,
      symbol: item.Symbol || item?.currency,
      // operation:
      //   item.type === 'receive_transaction'
      //     ? "Receive"
      //     : "Send",
      operation:
        item?.type === "send_transection"
          ? "Send"
          : item?.type === "recieve_transection"
            ? "Receive"
            : item.Transfer === JSON.parse(localStorage.getItem("user")).data.user.address ||
              item.Transfer === JSON.parse(localStorage.getItem("user")).data.user.sol_address
              || item.Transfer === JSON.parse(localStorage.getItem("user")).data.user.trc_address
              ? "Send"
              : "Receive",
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status || "completed"}</b>
          </h6>
        </span>
      ),
      approver: item.approver ? item.approver?.name : "-",
      action: (
        <HashLink onClick={() => openProductDetails(item._id)}>
          {/* <EyeIcon style={{ padding: '2px' }} /> */}
          <button
            className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none rounded-circle"
            type="button"
            style={{ marginTop: "0px" }}
            onClick={() => openProductDetails(item._id)}

          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </HashLink>
      ),
    })
  );

  // const refreshRequest = () => {
  //   fetchWalletSendReceiveActivities();
  // };

  const closeProductDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  const abortController1 = new AbortController();
  const { signal1 } = abortController1;

  const fetchWalletSendReceiveActivities1 = async () => {

    try {
      const requestData = {
        limit: 20,
        page_number: 1,
      };
      setIsLoading(true);

      const response = await walletSendReceiveActivities(requestData, {
        signal1,
      });

      if (!signal1.aborted && response.data) {
        const walletActivities = response.data.data;
        setTransactions(walletActivities);
        setTransactions1(walletActivities);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        // If signal is aborted, don't update the state (the component is unmounted)
        // eslint-disable-next-line no-lonely-if
        if (!signal1.aborted) {
          console.error(
            "Error fetching Wallet Send Receive Activities:",
            response.statusText
          );
        }
      }
    } catch (error) {
      setIsLoading(false);

      // If signal is aborted, don't update the state (the component is unmounted)
      if (!signal1.aborted) {
        console.error("Error fetching Wallet Send Receive Activities:", error);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchWalletSendReceiveActivities = async () => {
      setIsLoading(true);

      try {
        const requestData = {
          limit: 20,
          page_number: 1,
          trx_type: selectedHistoryOption
        };

        // if(selectedHistoryOption =="bank_history"){
        //   const response = await getAccountHistory();
        //   console.log("🚀 ~ fetchWalletSendReceiveActivities ~ response:", response)
        //   const walletActivities = response.data;
        //   setTransactions(walletActivities);
        //   setTransactions1(walletActivities);
        //   setIsLoading(false);

        // }else{



        const response = await walletSendReceiveActivities(requestData, {
          signal,
        });

        if (!signal.aborted && response.data) {
          setIsLoading(false);
          const walletActivities = response.data.data;
          setTransactions(walletActivities);
          setTransactions1(walletActivities);

        } else {
          // If signal is aborted, don't update the state (the component is unmounted)
          // eslint-disable-next-line no-lonely-if
          if (!signal.aborted) {
            setIsLoading(false);
            console.error(
              "Error fetching Wallet Send Receive Activities:",
              response.statusText
            );
          }
        }
        // }
      } catch (error) {
        // If signal is aborted, don't update the state (the component is unmounted)
        if (!signal.aborted) {
          setIsLoading(false);
          console.error(
            "Error fetching Wallet Send Receive Activities:",
            error
          );
        }
      }
    };



    const fetchData = async () => {
      try {
        await fetchWalletSendReceiveActivities();
      } catch (error) {
        // Handle any errors if necessary
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function to cancel ongoing tasks or subscriptions
      abortController.abort();
    };
  }, [selectedHistoryOption, reFetchActivities]);

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Wallet Send & Receive Activities"
        isLoading={isHistoryLoading || isTopTenLoading}
        refreshRequest={() => fetchWalletSendReceiveActivities1()}
      >
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">
              Search Filter
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                  onChange={handleSearchFilterChange}
                  options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {isSearchContract && (
            <Col xs={12} md={4} lg={3}>
              <InputDiv>
                <label
                  htmlFor="features"
                  className="form-label text-white mb-1"
                >
                  Contract
                </label>
                <ChatSearchInput
                  type="text"
                  className="form-control mt-2"
                  placeholder="Search by contract"
                  // onChange={handleFileChange}
                  id="File2"
                  name="File2"
                />
              </InputDiv>
            </Col>
          )}
          {/* {isSearchDate && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Date
              </label>
              <ChatSearchInput
                type="date"
                value={selectedDate}
                className="form-control mt-2"
                onChange={handleDateChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Name
              </label>
              <ChatSearchInput
                type="text"
                value={selectedName}
                className="form-control mt-2"
                placeholder="Search by name"
                onChange={handleNameChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchApprover && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Approver
              </label>
              <ChatSearchInput
                type="text"
                value={selectedApprover}
                className="form-control mt-2"
                placeholder="Search by approver"
                onChange={handleApproverChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}

          {/* {isSearchName && ( */}
          {/* )} */}
          {/* {isSearchAmount && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Amount
              </label>
              <ChatSearchInput
                type="text"
                value={selectedAmount}
                className="form-control mt-2"
                placeholder="Search by Amount"
                onChange={handleAmountChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOperation && ( */}
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Symbol
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSymbol}
                  onChange={handleSymbolChange}
                  options={dropdownOptions}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Operation
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOperation}
                  onChange={handleOrderOperationChange}
                  options={optionOperations}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Status
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOrderStatus}
                  onChange={handleOrderStatusChange}
                  options={optionOrderStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Wallet activity
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedHistoryOption}
                  onChange={(value) => {
                    setSelectedHistoryOption(value.value)
                  }}
                  options={historyOption}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchSymbol && ( */}
          <Col xs={12} md={4} lg={3}>
          {/* <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Type</label> */}
          <InputDiv className="px-0">
          <FormButton
            type="submit"
            variant="danger-outline"
            className="btn-block mt-4"
            onClick={() => resetData(1)}

          >
           Reset  <i className="fa fa-refresh" />
          </FormButton>
          </InputDiv>
        </Col>
          {/* )} */}
          {!isSearchApprover &&
            !isSearchName &&
            !isSearchStatus &&
            !isSearchDate &&
            !isSearchContract &&
            !isSearchSymbol &&
            !isSearchAmount &&
            !isSearchOperation && <Col xs={12} md={4} lg={3} />}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label
              htmlFor="color"
              style={{ fontSize: "small", color: "transparent" }}
              className="form-label"
            >
              .
            </label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset <i className="fa fa-refresh" />
            </Button>

            
          </Col>
        </Row>
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
        {/* <DashboardTableCrypto responsive bordered>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Contract</th>
              <th>Hash</th>
              <th>Approver</th>
              <th>Amount</th>
              <th>Symbol</th>
              <th>Operation</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ cursor: 'pointer' }}>
            { getTransactions.map((item, index) => (
              <tr>
                <td style={{ textAlign: 'center' }}>{item.Date}</td>
                <td style={{ textAlign: 'center' }}>
                  {item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  <span className="text-secondary text-xs font-weight-bold">
                    {item.type === 'contract'
    ? `Contract@ ${item.Name.substring(0, 12)}...`
    : 'Direct Transaction'}
                  </span>
                </td>
                
                <td className="align-middle text-center">
                  <a target="_blank" href={`https://bscscan.com/tx/${item.hash}`} rel="noreferrer">
                    <span className="text-purple text-xs font-weight-bold">
                      {item.hash.substring(0, 10)}...         
                    </span>
                  </a>
                </td>

                {item.type === 'contract' ? (
                  <td className="align-middle text-center">
                    <span className="text-secondary text-xs font-weight-bold">*****</span>
                  </td>
) : (
  <td className="align-middle text-center">
    <span className="text-secondary text-xs font-weight-bold">-</span>
  </td>
)}
                <td style={{ textAlign: 'center' }}>{item.Amount}</td>
                <td style={{ textAlign: 'center' }}>{item.Symbol}</td>
                <td style={{ textAlign: 'center' }}>
                  {item.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address ? 'Send' : 'Receive'}
                </td>
                <td style={{ textAlign: 'center' }}>{item.status}</td>
                <td style={{ textAlign: 'center' }}>
                  <button 
                    className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none"   
                    type="button" 
                    style={{ marginTop: '0px' }}
                    onClick={() => openProductDetails(item.hash)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </DashboardTableCrypto> */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalIsOpen}
          onHide={closeProductDetails}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: "large" }}>
              Transaction Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
              <>
                <div
                  className="container mt-0 containerDiv pt-0"
                  style={{ overflowX: "hidden" }}
                >
                  {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Name</b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-0"
                      >
                        {selectedOrder.Name}
                      </p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Transaction Hash</b>
                      </p>
                      {selectedOrder.hash ? <a
                        style={{ color: "#70bbfd", cursor: "pointer" }}

                        onClick={() => {



                          let baseURL;
                          if (["ETH", "USDT_ERC20", "USDT (ERC20)", "PAX", "GBPT", "EUROC"].includes(selectedOrder.Symbol)) {
                            baseURL = "https://etherscan.io/tx/";
                          } else if (["BNB", "CTY", "FTY", "OPY", "BUSD", "USDT", "MET$", "META"].includes(selectedOrder.Symbol)) {
                            baseURL = "https://bscscan.com/tx/";
                          }

                          else if (["TRON", "USDT (TRC20)"].includes(selectedOrder.Symbol)) {
                            baseURL = "https://tronscan.io/#/transaction/";
                          }
                          else if (["SOLANA", "LTX", "WEN", "SoLpy"]) {
                            baseURL = "https://explorer.solana.com/tx/"
                          }
                          else {
                            baseURL = "https://polygonscan.com/tx/";
                          }

                          { selectedOrder?.trx_status != "bank" && openInNewTab(`${baseURL}${selectedOrder.hash}`) };
                        }}
                        rel="noreferrer"
                      >
                        <span className="text-purple text-xs font-weight-bold">
                          {selectedOrder.hash.slice(0, 10) + "..." + selectedOrder.hash.slice(-10)}
                        </span>
                      </a> : <p style={{
                        textTransform: "Capitalize",
                        color: "#7b7b7b",
                      }}>N/A</p>}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Status</b>
                      </p>
                      <Badge
                        bg="dark"
                        className="mb-1"
                        style={{ fontSize: "small", fontWeight: "400" }}
                      >
                        {selectedOrder.status}
                      </Badge>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Amount</b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1 mb-2"
                      >
                        {selectedOrder.Amount} {selectedOrder.Symbol}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-9 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Fee</b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1 mb-2"
                      >
                        {selectedOrder.fee || '--'}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Transfer Detail</b>
                    </p>
                  </div>
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Transfer Name </b>
                      </p>
                      {selectedOrder.Transfer ? (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {selectedOrder.Transfer.name}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Transfer Address </b>
                      </p>

                      {selectedOrder.Transfer ? (
                        ["LTX", "WEN", "SOLANA"].includes(selectedOrder.Symbol) ? (
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                              wordWrap: "break-word",
                            }}
                            className="py-1"
                          >
                            {selectedOrder.Transfer.sol_address}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                              wordWrap: "break-word",
                            }}
                            className="py-1"
                          >
                            {selectedOrder.Transfer.address}
                          </p>
                        )
                      ) : (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}

                    </div>
                  </div>
                  {selectedOrder.Approver ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-0"
                        >
                          <b>Approver Detail</b>
                        </p>
                      </div>
                      <div
                        className="row mt-0 mb-2 mx-1"
                        style={{
                          border: "2px solid #7b7b7b",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            <b>Approver Name </b>
                          </p>
                          {selectedOrder.Approver ? (
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              *****
                            </p>
                          ) : (
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              -
                            </p>
                          )}
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            <b>Approver Address </b>
                          </p>
                          {selectedOrder.Approver ? (
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                                wordWrap: "break-word",
                              }}
                              className="py-1"
                            >
                              {selectedOrder.Approver.address}
                            </p>
                          ) : (
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              -
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="row mt-4 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Beneficiary Detail</b>
                    </p>
                  </div>
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Beneficiary Name</b>
                      </p>
                      {selectedOrder.Beneficiary ? (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {selectedOrder.Beneficiary.name}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Beneficiary Address</b>
                      </p>
                      {selectedOrder.Transfer ? (
                        ["LTX", "WEN", "SOLANA"].includes(selectedOrder.Symbol) ? (
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                              wordWrap: "break-word",
                            }}
                            className="py-1"
                          >
                            {selectedOrder.Beneficiary.sol_address}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                              wordWrap: "break-word",
                            }}
                            className="py-1"
                          >
                            {selectedOrder.Beneficiary.address}
                          </p>
                        )
                      ) : (
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}
                    </div>
                  </div>
                  <hr
                    hidden
                    style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                  />
                </div>
                <button type="button" hidden>
                  Submit Ratings
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </Panel>
    </>
  );
};

export default TopActivies;

// region STYLES

const DashboardTableCrypto = styled(Table)`
  tbody td {
    padding: 10px;
    white-space: nowrap;
  }
`;

const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

// endregion
