import React, { useEffect, useState } from 'react';
import {
  Badge,
  Col,
  Nav,
  Tab,
} from 'react-bootstrap';
import ProfileTimeLine from '@/shared/components/ProfileTimeLine';
import {
  TabsWrap, NavLink, NavItem, BorderedBottomTabs,
} from '@/shared/components/Tabs';
import { Card } from '@/shared/components/Card';
import showResults from '../../../../utils/showResults';
import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
import ProfileSetting from './ProfileSetting';
import { ProfileCard } from '../ProfileBasicComponents';
import StrigaDetails from './StrigaDetail';
import KYC from './KYC';
import styled from "styled-components";

import './card.css'
import { blockOrUnblockCard, createCard, getAllCards, updateCardPassword } from '../../../../utils/services/user.service';
import moment from 'moment';
import { colorAccent } from '../../../../utils/palette';
import { Switch, FormControlLabel } from "@mui/material";
import { Field } from "formik";
import { Ring } from '@uiball/loaders';
import { Button } from "@/shared/components/Button";
import { toast } from 'react-toastify';


const ProfileTabs = () => {
  const initialValues = {
    username: 'Larry Boom',
    email: 'boom@mail.com',
  };
  const [cardData, setCardData] = useState(null)
  const [physicalCardData, setPhysicalCardData] = useState(null)
  const [checked, setChecked] = useState(true);
  const [physicalChecked, setPhysicalChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [bankPassword, setBankPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [ChangePassword, setChangePassword] = useState(false);
  const [cardId, setCardId] = useState("")

  const getBankCardDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getAllCards();
      const data = res?.data?.cards;

      // Find the first physical card
      const physicalCard = data.find(card => card.type === 'PHYSICAL');
      if (physicalCard) {
        setPhysicalCardData(physicalCard);
        setPhysicalChecked(physicalCard.status === "CREATED" ? true : false);
      }

      // Find the first virtual card
      const virtualCard = data.find(card => card.type === 'VIRTUAL');
      if (virtualCard) {
        setCardData(virtualCard);
        setChecked(virtualCard?.status === "ACTIVE" ? true : false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBankCardDetails()

  }, [])

  const formatCardNumber = (number) => {
    const visibleDigits = number?.replace(/\*/g, '*'); // Replace * with • for visibility
    const groups = [];
    let startIndex = 0;

    // Split into groups of 4 characters or less
    while (startIndex < visibleDigits.length) {
      groups.push(visibleDigits.substring(startIndex, startIndex + 4));
      startIndex += 4;
    }

    return groups;
  };

  const formattedCardNumber = formatCardNumber(cardData?.maskedCardNumber || "");
  const formattedPhysicalCardNumber = formatCardNumber(physicalCardData?.maskedCardNumber || "");

  const setBlockOrUnblockCard = async (val) => {
    setIsLoading(true)
    try {
      const payload = {
        "status": val == true ? "block" : "unblock",
        "cardId": cardData?.id
      }
      const res = await blockOrUnblockCard(payload)
      toast.success(res?.data?.Message)
      getBankCardDetails()
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
    }
  }


  const handleChange = (event) => {
    setChecked(event.target.checked);
    setBlockOrUnblockCard(event.target.checked)

  };

  const handleCreateBankCard = async (val) => {
    setIsLoading(true)
    try {
      const payload = {
        password: bankPassword,
        type: val
      }
      const res = await createCard(payload)
      toast.success(res?.data?.Message)

      getBankCardDetails()
      setIsLoading(false)

    } catch (error) {

      toast.error(error?.response?.data?.Message || error?.response?.data?.message)
      setIsLoading(false)


    }
  }
  const handleChangePassword = async () => {
    setIsLoading(true)
    try {
      const payload = {
        password: newPassword,
        cardId: cardData?.id
      }
      const res = await updateCardPassword(payload)
      toast.success(res?.data?.Message)
      getBankCardDetails()
      setIsLoading(false)
      setChangePassword(false)

    } catch (error) {
      toast.error(error?.response?.data?.Message || error?.response?.data?.message)
      setIsLoading(false)


    }
  }
  return (
    <Col md={12} lg={12} xl={7}>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Card>
        <BorderedBottomTabs as={ProfileCard}>
          <Tab.Container defaultActiveKey="3">
            <TabsWrap>
              <Nav className="nav-tabs">
                {/* <NavItem>
                  <NavLink eventKey="1">
                    Activity
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink eventKey="2" style={{ color: '#dddddd' }}>
                    Timeline
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="3">
                    Settings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="4">
                    Security
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="6">
                    Custodiy KYC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="5">
                    Bank Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="7">
                    Bank Card
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink style={{ color: '#dddddd' }} eventKey="5">
                    Timeline
                  </NavLink>
                </NavItem> */}
              </Nav>
              <Tab.Content>
                {/* <Tab.Pane eventKey="1">
                  <ProfileActivities />
                </Tab.Pane> */}
                <Tab.Pane eventKey="2">
                  <ProfileTimeLine />
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <ProfileSettings onSubmit={showResults} initialValues={initialValues} />
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <ProfileSetting onSubmit={showResults} initialValues={initialValues} />
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  <KYC onSubmit={showResults} initialValues={initialValues} />
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  {/* <StrigaDetails onSubmit={showResults} initialValues={initialValues} /> */}
                  <p>Coming Soon...</p>
                </Tab.Pane>
                <Tab.Pane eventKey="7">
                  <>
                  <p>Coming Soon...</p>
                  {/* {
                    !cardData ? (
                      <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div className="col-12 mt-1">
                          <InputDiv>
                            <label htmlFor="email" className="text-white mb-3">
                              Password <sup style={{
                                fontSize: '10px'
                              }}>
                                (8-36 Characters)
                              </sup> *
                            </label>
                            <ChatSearchInput
                              name="amount"
                              type="password"
                              placeholder="Enter Password"
                              value={bankPassword}
                              onChange={(value) => {
                                const password = value.target.value;
                                setBankPassword(password);
                              }}
                              className={`form-control w-100 `}
                            />


                          </InputDiv>
                        </div>

                        <FormButton variant="outline-primary" onClick={() => handleCreateBankCard("VIRTUAL")} disabled={!bankPassword || bankPassword.length < 8} className={'col-12 col-md-4'} type="submit">
                          Create Virtual Card
                        </FormButton>
                      </div>
                    ) :
                      (
                        <>
                          <StyledDiv>
                            <div className='d-flex flex-column gap-3 p-3 main'>
                              <div className='d-flex justify-content-between'>
                                <img src='https://i.ibb.co/G9pDnYJ/chip.png' style={{
                                  width: '32px',
                                  height: '32px'
                                }} alt='chip' />

                                <h4>Virtual Bank Card</h4>
                              </div>

                              <div className='d-flex flex-column align-items-end flex-md-row gap-2 gap-md-4'>
                                {formattedCardNumber.map((group, index) => (
                                  <h3 key={index}>{group}</h3>
                                ))}
                              </div>

                              <div className='d-flex flex-column flex-md-row justify-content-between'>
                                <h5>CARD HOLDER</h5>
                                <h4 className='d-flex  text-uppercase'>{cardData?.name}</h4>
                              </div>
                              <div className='d-flex flex-column flex-md-row justify-content-between'>
                                <h5>VALID TILL</h5>
                                <h4>{moment(cardData?.expiryData).format('MM / YY')}</h4>
                              </div>
                              <div className='d-flex flex-row align-items-center flex-md-row justify-content-between'>
                                <span
                                  style={{
                                    textTransform: "Capitalize",
                                    float: "left",
                                  }}
                                  className={` px-2 d-flex border rounded ${cardData?.status === "ACTIVE"
                                    ? "badge-success"
                                    : "badge-danger"}`}
                                >
                                  <h5> {cardData?.status}</h5>
                                </span>
                                <Switch onChange={handleChange}
                                  checked={checked} />
                              </div>
                            </div>
                          </StyledDiv>

                          {!ChangePassword && <FormButton variant="outline-primary" onClick={() => setChangePassword(true)} className={'col-12 col-md-4'} type="submit">
                            Change Password
                          </FormButton>}

                        </>
                      )
                  }

                  {
                    ChangePassword && (
                      <div className='d-flex flex-column align-items-center mt-2 justify-content-center'>
                        <div className="col-12 mt-1">
                          <InputDiv>
                            <label htmlFor="email" className="text-white mb-3">
                              New Password <sup style={{
                                fontSize: '10px'
                              }}>
                                (8-36 Characters)
                              </sup> *
                            </label>
                            <ChatSearchInput
                              name="amount"
                              type="password"
                              placeholder="Enter New Password"
                              value={newPassword}
                              onChange={(value) => {
                                const password = value.target.value;
                                setNewPassword(password);
                              }}
                              className={`form-control w-100 `}
                            />


                          </InputDiv>
                        </div>

                        <FormButton variant="outline-primary" onClick={() => handleChangePassword()} disabled={!newPassword || newPassword.length < 8} className={'col-12 col-md-4'} type="submit">
                          Change Password
                        </FormButton>
                      </div>
                    )
                  } */}

                  {/* physical card  */}

                  {/* {
                    !physicalCardData ? (
                      <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div className="col-12 mt-1">
                          <InputDiv>
                            <label htmlFor="email" className="text-white mb-3">
                              Password <sup style={{
                                fontSize: '10px'
                              }}>
                                (8-36 Characters)
                              </sup> *
                            </label>
                            <ChatSearchInput
                              name="amount"
                              type="password"
                              placeholder="Enter Password"
                              value={bankPassword}
                              onChange={(value) => {
                                const password = value.target.value;
                                setBankPassword(password);
                              }}
                              className={`form-control w-100 `}
                            />


                          </InputDiv>
                        </div>

                        <FormButton variant="outline-primary" onClick={() => handleCreateBankCard("PHYSICAL")} disabled={!bankPassword || bankPassword.length < 8} className={'col-12 col-md-4'} type="submit">
                          Create Physical Card
                        </FormButton>
                      </div>
                    ) :
                      (
                        <>
                          <StyledDiv>
                            <div className='d-flex flex-column gap-3 p-3 main'>
                              <div className='d-flex justify-content-between'>
                                <img src='https://i.ibb.co/G9pDnYJ/chip.png' style={{
                                  width: '32px',
                                  height: '32px'
                                }} alt='chip' />

                                <h4>Physical Bank Card</h4>
                              </div>

                              <div className='d-flex flex-column align-items-end flex-md-row gap-2 gap-md-4'>
                                {formattedPhysicalCardNumber.map((group, index) => (
                                  <h3 key={index}>{group}</h3>
                                ))}
                              </div>

                              <div className='d-flex flex-column flex-md-row justify-content-between'>
                                <h5>CARD HOLDER</h5>
                                <h4 className='d-flex  text-uppercase'>{physicalCardData?.name}</h4>
                              </div>
                              <div className='d-flex flex-column flex-md-row justify-content-between'>
                                <h5>VALID TILL</h5>
                                <h4>{moment(physicalCardData?.expiryData).format('MM / YY')}</h4>
                              </div>
                              
                            </div>
                          </StyledDiv>

                          {!ChangePassword && <FormButton variant="outline-primary" onClick={() => setChangePassword(true)} className={'col-12 col-md-4'} type="submit">
                            Change Password
                          </FormButton>}

                        </>
                      )
                  }

                  {
                    ChangePassword && (
                      <div className='d-flex flex-column align-items-center mt-2 justify-content-center'>
                        <div className="col-12 mt-1">
                          <InputDiv>
                            <label htmlFor="email" className="text-white mb-3">
                              New Password <sup style={{
                                fontSize: '10px'
                              }}>
                                (8-36 Characters)
                              </sup> *
                            </label>
                            <ChatSearchInput
                              name="amount"
                              type="password"
                              placeholder="Enter New Password"
                              value={newPassword}
                              onChange={(value) => {
                                const password = value.target.value;
                                setNewPassword(password);
                              }}
                              className={`form-control w-100 `}
                            />


                          </InputDiv>
                        </div>

                        <FormButton variant="outline-primary" onClick={() => handleChangePassword()} disabled={!newPassword || newPassword.length < 8} className={'col-12 col-md-4'} type="submit">
                          Change Password
                        </FormButton>
                      </div>
                    )
                  } */}
</>

                </Tab.Pane>
              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </BorderedBottomTabs>
      </Card>
    </Col>
  );
};

export default ProfileTabs;

const StyledDiv = styled.div`
  background-image: url('https://i.ibb.co/PYss3yv/map.png'), linear-gradient(45deg, #0045c7, #ff2c7d);
  background-blend-mode: overlay;
  border: 1px solid white;
  border-radius: 18px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  height: 250px;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 70%;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 12px;
    height: 420px;

  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;