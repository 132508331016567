/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable radix */
import React, { useState, useEffect } from "react";
import { validate, Network } from "bitcoin-address-validation";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Field, Formik } from "formik";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { WagmiConfig, useAccount } from "wagmi";
import Panel from "@/shared/components/Panel";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import config from "@/config/walletConnect";
import { Button } from "../../../../shared/components/Button";
import Tfaaa from "../../../Account/MyAccount/Tfaaa";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import {
    API_URL,
    approveSenExternalWalletOtp,
    getApprover,
    getFee,
    getStrigaWalletAddress,
    getUserInfo,
    sendSubWalletSolToken,
    userBalanceInternalWalletSubWallet,
    verifyTFAEmail,
} from "../../../../utils/services/user.service";
import { clearMsg } from "../../../../redux/actions/authActions";
import authHeader from "../../../../utils/services/auth-header";
import { getTotalBalance } from "../../../../redux/actions/sidebarActions";
import SimpleModal from "../../../../shared/components/Modal/SimpleModal";
import Abi from "../../NewContract/component/Abi/tokenAbi.json";
import ContractAbi from "../../NewContract/component/Abi/contractAbi.json";
import { colorAccent } from "../../../../utils/palette";
// import { sendToken } from "../../../../utils/services/auth.services";
import { Switch, FormControlLabel, Tooltip } from "@mui/material";
import { getUserBalanceExternalWalletBSCList, sendToken, sendSubWalletToken } from "../../../../utils/services/user.service";
import { externalWalletAddresses } from "../../../../utils/constants/contants";
import { toast } from "react-toastify";
import Coins1 from "../../Wallet/components/Coins1";
import { useChainContext } from "../../../context/context";
import numeral from "numeral";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import Web3ButtonPage from "../../NewContract/component/W3ButtonPage";
import { Web3Modal } from "@web3modal/react";
import WalletResponse from "../../NewContract/component/WalletResponse";
import SendForm from "./SendForm";
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import {
    configureChains, createConfig,
} from 'wagmi';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';

const user = JSON.parse(localStorage.getItem("user"));

const VirtualWalletSendForm = ({ benficiaryToggle, message, chain, errorMsg, walletOption }) => {

    const account = useAccount(); // Get External wallet status
    const { REACT_APP_BSC_CONTRACT_ADDRESS, REACT_APP_BSC_DEFAULT_TOKEN, projectId } =
        config;
    const {
        REACT_APP_SMART_CHAIN,
    } = config;
    const { setRefreshInternalBalance, refreshInternalBalace } = useChainContext()
    const [uSDCoin, setUSDCoin] = useState("");
    const [otp, setOtp] = useState(false);
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState(200);
    const [approverData, setapproverData] = useState([]);
    const [defaultBenificiary, setDefaultBenificiary] = useState(null);

    const [getApproverID, setApproverID] = useState("");
    const [loading, setloading] = useState(false);
    const [isLoading1, setisLoading1] = useState(false);
    const [load, setloader] = useState(false);
    const [isWallet, setisWallet] = useState(false);
    const [tokenBalance, setTokenBalance] = useState(null);
    const [externalCoin, setExternalCoin] = useState(null);
    const [walletCoin, setwalletCoin] = useState("");
    const [contractAddress, setContractAddress] = useState("");
    const [options, setOptions] = useState([]);
    const [IsBtc, setIsBtc] = useState(false);
    const [btcAddress, setBtcAddress] = useState("");
    const [validBtcAddress, setValidBtcAddress] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);
    const [count, setcount] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [transactionCoin, setTransactionCoin] = useState(null);
    const [walletError, setWalletError] = useState(null);
    const [walletSuccess, setWalletSuccess] = useState(null);
    const [tfaUrl, setTfaUrl] = useState(false);
    const [tfaEmail, setTfaEmail] = useState("");
    const [otpbtn, setOtpbtn] = useState(true);
    const [dataFromChild, setDataFromChild] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [amount1, setAmount] = useState("");
    const [receiver1, setReceiver] = useState("");

    const [recieverSolAddress, setRecieverSolAddress] = useState("");
    const [recieverTrcAddress, setRecieverTrcAddress] = useState("");
    const [address, setAdddress] = useState("");
    const [coin1, setCoin] = useState("");
    const [otp1, setOtp1] = useState("");
    const [statusCode, setstatusCode] = useState(400);

    const [externalWalletBSCList, setExternalWalletBSCList] = useState([]);

    const [externalCoinBalance, setExterCoinBalance] = useState(0);
    const [externalCoinAmount, setExterCoinAmount] = useState(0);

    const [exterCoinSymbol, setExterCoinSymbol] = useState(0);
    const [internalAmount, setInternalAmount] = useState(0);

    const [internalCoinApiAmount, setInternalCoinApiAmount] = useState(null);
    const [internalPrice, setInternalPrice] = useState(null);

    const [userInfoData, setUserData] = useState(null);
    const [btnDisable, setBtnDisable] = useState(false);
    const [isTfaVarified, setIsTfaVarified] = useState(false);
    const userSessionData = JSON.parse(localStorage.getItem('user'));
    const [tfaCode, setTfaCode] = useState('');
    // const [walletOption, setWalletOption] = useState('main_wallet');
    const [withdrawFee, setWithdrawFee] = useState(null);
    const [destinationAddress, setDestinationAddress] = useState(null);
    const [destinationWallet, setDesinatioWallet] = useState(null);
    const [coinList, setCoinList] = useState([])
    const [onChainWalletAddress, setOnchainWalletAddress] = useState(null)
    const [selectedVirtualWalletFrom, setSelectedVirtualWalletFrom] = useState("internal")
    const [selectedVirtualWalletTo, setSelectedVirtualWalletTo] = useState("external_wallet")
    const chains = [arbitrum, mainnet, polygon];
    const [searchLoad, setsearchLoad] = useState(false);
    const { setRefetchActivities, reFetchActivities } = useChainContext()

    const [ChainValue, setChainValue] = useState(REACT_APP_SMART_CHAIN);

    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
    const wagmiConfig = createConfig({
        autoConnect: true,
        connectors: w3mConnectors({ projectId, chains }),
        publicClient,
    });
    const ethereumClient = new EthereumClient(wagmiConfig, chains);

    const [strigaWAlletAddress, setStrigaWalletAddress] = useState(null)



    const getStrigaWallet = async (id) => {
        try {
            const payload = {
                "striga_user_id": id
            }
            const res = await getStrigaWalletAddress(payload)
            setStrigaWalletAddress(res?.data?.blockchainDepositAddress)

        } catch (error) {
            toast.error("You don't have bank Account")

        }

    }
    useEffect(() => {
        if (selectedVirtualWalletTo == "bank") {
            getStrigaWallet(onChainWalletAddress?.striga_user_id)
        }

    }, [selectedVirtualWalletTo, onChainWalletAddress])


    const handleTfaCode = async () => {
        const requestData = {
            token: tfaCode,
            email: userSessionData.data.user.email,
        };
        try {
            setIsLoading(true);
            const res = await verifyTFAEmail(requestData);

            if (res) {
                setIsLoading(false);
                if (res.data.verified === false) {
                    setTfaCode('');
                    toast.error('Incorrect TFA Code', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else if (res.data.verified === true) {
                    setIsTfaVarified(true)
                    toast.success('Verify TFA Successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setIsLoading(false);
                    setTfaCode('');
                    toast.error('Verify TFA failed', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {

                setIsLoading(false);
                console.error('verifyTFA failed:', res);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        const abortController = new AbortController();
        const { signal } = abortController;

        return () => {
            abortController.abort();
        };
    }, []);

    useEffect(() => {
        if (coin1 && receiver1 && selectedVirtualWalletTo == 'external') {
            if (["LTX", "WEN", "SOLANA"].includes(coin1) && !recieverSolAddress) {
                toast.error('You are on the wrong chain');
                setBtnDisable(true)
                return
            }
            setBtnDisable(false)
        }
    }, [coin1, receiver1, recieverSolAddress]);

    const handleApproverChange = (event) => {
        const selectedID = event.value;
        setReceiver(selectedID);
    };

    const approverOptions = approverData.map((approver) => ({
        value: approver.address,
        label: approver.label,
    }));

    const walletAddressOption = [
        {
            label: 'Internal Wallet',
            value: "internal_wallet"
        },
        {
            label: 'External Wallet',
            value: "external_wallet"
        }
    ]

    let defaultOptions = [];
    let ethirumOptions = [];
    let bsc = [];
    let maticOptions = [];
    let isMounted = true;

    const getContractDetails = () => {
        setisLoading1(true);
        dispatch(fetchAllCurrencies1())
            .then(() => {
                if (isMounted) {
                    setisLoading1(false);
                }
            })
            .catch((error) => {
                // Handle any errors if needed
                if (isMounted) {
                    setisLoading1(false);
                }
            });
    };

    useEffect(() => {
        getContractDetails();

        return () => {
            // Cleanup function to cancel ongoing tasks or subscriptions
            isMounted = false;
        };
    }, [dispatch]);

    const { coinList1 } = useSelector((state) => ({
        coinList1: state.coinList1.data,
    }));


    const SmallLabel = ({ text, smallText }) => (
        <span>
            {text}{" "}
            <small>
                <b>{smallText}</b>
            </small>
        </span>
    );


    useEffect(() => {
        const updateOptions = async () => {
            // internal coins list from Redux state

            const defaultOptions = coinList1?.map((item) => ({
                value: item?.symbol,
                label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
                price: item?.price,
                chain_id: item?.chain_id
            }));

            setOptions(defaultOptions);

            const newDataArray = externalWalletBSCList.map((item2) => {
                const matchingItem1 = externalWalletAddresses.find(
                    (item1) => item1.symbol === item2.symbol
                );

                return {
                    value: matchingItem1 ? matchingItem1.value : "",
                    label: <SmallLabel text={item2.symbol} smallText={item2.quantity} />,
                    priceDta: item2,
                };
            });


            const bsc = newDataArray.map((newData) => {
                return {
                    value: newData?.value,
                    label: (
                        <SmallLabel
                            text={newData?.label?.props?.text}
                            smallText={newData?.label?.props?.smallText}
                        />
                    ),
                    price: newData,
                    chain_id: newData?.chain_id

                };
            });

            const allowedSymbols = ["USDC", "EUR", "BTC", "BNB", "ETH", "USDT"];

            if (selectedVirtualWalletTo == 'bank') {
                const bankCoins = coinList1
                    ?.filter(item => allowedSymbols.includes(item?.symbol))
                    .map(item => ({
                        value: item?.symbol,
                        label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
                        price: item?.price,
                        chain_id: item?.chain_id
                    }));
                setOptions(bankCoins);

                return

            }


            if (walletOption === 'sub_wallet') {
                const onChain = coinList1?.map((item) => ({
                    value: item?.symbol,
                    label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
                    price: item?.price,
                    chain_id: item?.chain_id
                }));

                setOptions(onChain);
            }
            if (benficiaryToggle === "external") {
                setOptions(bsc);
            }
            if (benficiaryToggle === "external" && account.isDisconnected) {
                setOptions(bsc);
            }
        };
        if (coinList1.length > 0) {
            updateOptions();
        }

    }, [coinList1, coinList, selectedVirtualWalletTo, walletOption]);

    const fetchMaxBalance = () => {
        let coinList = [];
        let mapping = {};
        if (benficiaryToggle !== "external") {
            coinList = coinList1;
            coinList.forEach((coin, index) => {
                mapping[coin.symbol] = index;
            });
        } else if (benficiaryToggle === "external") {
            coinList = externalWalletBSCList;
            coinList.forEach((coin, index) => {
                mapping[coin.symbol] = index;
            });
        }
        const index = benficiaryToggle !== "external" ? mapping[coin1] : mapping[exterCoinSymbol];

        if (index !== undefined) {
            setAmount(coinList[index]?.quantity
            );
        }
    };

    async function fetchBalance() {
        // console.log(chain);
        if (window.ethereum) {
            // console.log('eth');
            const web3 = new Web3(window.ethereum);
            if (account.address !== undefined) {
                const connection = await web3.eth.getChainId();
                const chainId = Number(connection);

                if (!account.address) {
                    setMsg("");
                }

                let tokenbalance = 0;
                if (chain === "BTC") {
                    axios
                        .post(
                            `${API_URL}getBtcWalletBalance`,
                            { walletAddress: btcAddress },
                            {
                                headers: authHeader(),
                            }
                        )
                        .then((response) => {
                            if (response.data.success === true) {
                                tokenbalance = response.data.balance;
                                setIsBtc(false);
                            }
                        });
                } else if (
                    walletCoin &&
                    account.address &&
                    walletCoin !== "BNB" &&
                    walletCoin !== "ETH" &&
                    walletCoin !== "MATIC"
                ) {
                    // console.log('errror1', walletCoin);
                    try {
                        const tokenContract = new web3.eth.Contract(Abi, walletCoin);
                        tokenbalance = await tokenContract.methods
                            .balanceOf(account.address)
                            .call();
                    } catch (error) {
                        setStatus(400);
                        setMsg(`Catch Error:${error?.message}`);
                        // console.log(error);
                    }
                } else {
                    // console.log('errror');
                    await web3.eth
                        .getBalance(account.address)
                        .then((balance) => {
                            // console.log(balance);
                            const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
                            tokenbalance = web3.utils.fromWei(balanceInWei, "ether"); // Convert to Ether
                            // console.log(Number(tokenbalance), '--------------');
                        })
                        .catch((error) => {
                            if (error.message === "Network Error") {
                                //  setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                            } else {
                                // setMsg(`CatchError:${error}${error?.message}`);
                            }
                        });
                }
                // console.log('tokenbalance', tokenbalance);
                if (Number(tokenbalance) === 0 && account.address) {
                    setMsg("Not Enough Balance to create contract");
                } else {
                    setMsg("");
                }
                setTokenBalance(Number(tokenbalance));
            } else if (chain === "BTC") {
                // console.log('asdasdadd');
                axios
                    .post(
                        `${API_URL}getBtcWalletBalance`,
                        { walletAddress: btcAddress },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then((response) => {
                        if (response.data.success === true) {
                            const tokenbalance = response.data.balance;

                            if (Number(tokenbalance) === 0) {
                                setStatus(400);
                                setMsg("Not Enough Balance to create contract");
                            } else {
                                setMsg("");
                            }
                            setTokenBalance(Number(tokenbalance));
                        }
                    })
                    .catch((error) => {
                        setStatus(400);
                        if (error.message === "Network Error") {
                            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                        } else {
                            // setMsg(`CatchError:${error}${error?.message}`);
                        }
                    });
            } else {
                setMsg("");
            }
        }
    }

    useEffect(() => {
        if (benficiaryToggle === "external") {
            setWalletError(false);
            setWalletError(false);
            // console.log('chain', REACT_APP_SMART_CHAIN);
            // if (chain === REACT_APP_SMART_CHAIN) {
            // console.log('yes');
            setOptions(bsc);
            setContractAddress(REACT_APP_BSC_CONTRACT_ADDRESS);
            setwalletCoin(REACT_APP_BSC_DEFAULT_TOKEN);
            setExternalCoin("BNB");
            setTokenBalance(0);
            fetchBalance();

        } else {
            setOptions(defaultOptions);
        }
    }, [chain]);

    React.useEffect(() => {
        if (account.address !== undefined) {
            setisWallet(true);

        }

    }, [account]);

    const transferWallet = async () => {
        const valuesToken = coin1 ? externalCoin : coin1;
        const web3 = new Web3(window.ethereum);
        const convertedIntoWei = web3.utils.toWei(amount1, "ether");
        let receipt = null;
        if (
            transactionCoin === "BNB" ||
            transactionCoin === "MATIC" ||
            transactionCoin === "ETH"
        ) {

            try {
                const createTransaction = {
                    from: account.address,
                    to: receiver1,
                    value: convertedIntoWei,
                    gas: 300000,

                };

                receipt = await web3.eth.sendTransaction(createTransaction);
            } catch (error) {
                setloader(false);
                setWalletError("Transaction failed. Something went wrong. Try again");
                // console.log(error);
            }
            // console.log(receipt);
        } else {
            try {
                const tokenAddress = walletCoin;
                const tokenContract = new web3.eth.Contract(Abi, tokenAddress);
                const tokenbalance = await tokenContract.methods
                    .balanceOf(account.address)
                    .call();
                const tokenConvertBalance = web3.utils.fromWei(tokenbalance, "ether");
                receipt = await tokenContract.methods
                    .transfer(receiver1, convertedIntoWei)
                    .send({ from: account.address, gas: 300000 });
                // console.log(receipt);
            } catch (error) {
                setloader(false);
                setWalletError("Transaction failed. Something went wrong. Try again");
                console.log(error);
            }
        }

        if (receipt) {
            setWalletError(null);
            setWalletSuccess(
                `Transaction Successfully Done!!! Here is Trasaction Hash,\n ${receipt.transactionHash}`
            );
            const userAdress = JSON.parse(localStorage.getItem("user"));
            const formData = {
                amount: amount1,
                senderWalletAddress: account.address,
                receiverWalletAddress: receiver1,
                hash: receipt.transactionHash,
                coin: transactionCoin,
                chainId: chain,
                email: userAdress.data.user.email,
            };
            const url = "sendTokenExternal";
            axios
                .post(API_URL + url, formData, {
                    headers: authHeader(),
                })
                .then((response) => {
                    // console.log(response);
                    // resetForm({ values: '' });
                    setTfaUrl(false);
                    setTfaEmail("");
                    setOtpbtn(true);
                    setDataFromChild(false);
                    setAmount("");
                    setReceiver("");
                    setAdddress("");
                    setCoin("");
                    setOtp1("");
                    setOtp(false);
                    setloading(false);
                });
        }
    };

    const saveToken = async () => {

        if (benficiaryToggle === "external") {
            if (isWallet) {
                try {
                    await approveSenExternalWalletOtp(otp1).then(
                        async (response) => {
                            // console.log(response.data);
                            if (response.data.status === 200) {
                                setMinutes(0);
                                setSeconds(0);
                                setResendOtp(false);
                                const { gasOnHold, gasFee, amount } = response.data.response;
                                if (tokenBalance === 0) {
                                    setloading(false);
                                    setStatus(400);
                                    setMsg("Not Enough Balance to create contract");
                                    setOtp(false);
                                    // console.log('not enough');
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    // setTimeout(() => {
                                    //   setMsg('');
                                    // }, 3000);
                                    return false;
                                }

                                if (tokenBalance - gasOnHold < gasFee) {
                                    setloading(false);
                                    setStatus(400);
                                    setMsg(
                                        "Gas is not enough to create contract for schedule transaction"
                                    );
                                    setOtp(false);
                                    // console.log('gas not enough');
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    // setTimeout(() => {
                                    //   setMsg('');
                                    // }, 3000);
                                    return false;
                                }

                                if (tokenBalance < parseInt(amount) + parseInt(amount1)) {
                                    setloading(false);
                                    setStatus(400);
                                    setMsg("Insufficient Fund!");
                                    setOtp(false);
                                    // console.log('not Insufficient');
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    // setTimeout(() => {
                                    //   setMsg('');
                                    // }, 3000);
                                    return false;
                                }
                                // console.log('*****************************');
                                await transferWallet();
                                setloading(false);
                            }
                        },
                        (error) => {
                            const message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setStatus(400);
                            setloading(false);
                            if (error.response.data.status === 400) {
                                setMsg(
                                    error.response.data.Message
                                        ? error.response.data.Message
                                        : error.response.data.Error
                                );
                                if (error.response.data.Message === "Invalid Otp") {
                                    setResendOtp(true);
                                    setcount(count + 1);
                                } else {
                                    setOtp(false);
                                    setMinutes(0);
                                    setSeconds(0);
                                    //  resetForm({ values: '' });
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    setTimeout(() => {
                                        setMsg("");
                                        setOtp(false);
                                    }, 3000);
                                }
                            } else {
                                setMsg(message);
                                setloading(false);
                                setTfaUrl(false);
                                setTfaEmail("");
                                setOtpbtn(true);
                                setDataFromChild(false);
                                setAmount("");
                                setReceiver("");
                                setAdddress("");
                                setCoin("");
                                setOtp1("");
                                setTimeout(() => {
                                    setMsg("");
                                    setOtp(false);
                                }, 3000);
                            }
                        }
                    );
                } catch (error) {
                    setStatus(400);
                    setloading(false);
                    // resetForm({ values: '' });
                    setTfaUrl(false);
                    setTfaEmail("");
                    setOtpbtn(true);
                    setDataFromChild(false);
                    setAmount("");
                    setReceiver("");
                    setAdddress("");
                    setCoin("");
                    setOtp1("");
                    setOtp(false);
                    setTimeout(() => {
                        setMsg("");
                    }, 5000);
                    if (error.message === "Network Error") {
                        // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                    } else {
                        setMsg(`CatchError:${error}${error?.message}`);
                    }
                }
            }
        } else {
            setIsLoading(true);
            try {
                let modifiedCoin;
                let modifiedReceiver;

                if (coin1 === "USDT (ERC20)") {
                    modifiedCoin = "USDT_ERC20";
                } else if (coin1 === "USDT (BEP20)") {
                    modifiedCoin = "USDT";
                } else {
                    modifiedCoin = coin1;
                }

                if (coin1 === "TRC" || coin1 === "USDT (TRC20)") {
                    if (selectedVirtualWalletTo === 'external_wallet') {
                        modifiedReceiver = receiver1 || defaultBenificiary;
                    } else if (selectedVirtualWalletTo === 'onChain_wallet' || selectedVirtualWalletTo === 'internal_custodiy') {
                        modifiedReceiver = recieverTrcAddress;
                    }
                }

                if (coin1 === "LTX" || coin1 === "WEN" || coin1 === "SOLANA" || coin1 === "SoLpy") {
                    if (selectedVirtualWalletTo === 'external_wallet') {
                        modifiedReceiver = receiver1 || defaultBenificiary;
                    } else if (selectedVirtualWalletTo === 'onChain_wallet') {
                        modifiedReceiver = onChainWalletAddress?.onChainAddress;
                    } else {
                        modifiedReceiver = recieverSolAddress;
                    }
                } else {
                    modifiedReceiver = receiver1 || defaultBenificiary;
                }

                let sendTokenFunction;
                let sendTokenPayload;

                const solCoins = ['SOL', 'WEN', 'LTX', 'SoLpy', 'SOLANA'];

                if (solCoins.includes(modifiedCoin) && walletOption === 'sub_wallet') {
                    sendTokenFunction = sendSubWalletSolToken;
                    sendTokenPayload = { coin: modifiedCoin, amount: amount1, otp: otp1, wallet_address: destinationWallet || onChainWalletAddress?.onChainSolAddress };
                } else if (walletOption === 'sub_wallet') {
                    sendTokenFunction = sendSubWalletToken;
                    sendTokenPayload = { coin: modifiedCoin, amount: amount1, otp: otp1, wallet_address: destinationWallet || onChainWalletAddress?.onChainAddress };
                } else {
                    sendTokenFunction = sendToken;
                    sendTokenPayload = { amount: amount1, receiver: modifiedReceiver, coin: modifiedCoin, otp: otp1 };
                }

                await sendTokenFunction(sendTokenPayload)
                    .then(
                        (res) => {
                            console.log("🚀 ~ saveToken ~ res:-----", res)
                            if (res.status === 200) {
                                setStatus(200);
                                setMsg(res?.data?.receipt);
                                toast.success(res?.data?.receipt)
                                setRefreshInternalBalance(true)
                                setloading(false);
                                setIsLoading(false);
                                dispatch(getTotalBalance());
                                dispatch(fetchAllCurrencies1())
                                setRefetchActivities(!reFetchActivities)
                                setTimeout(() => {
                                    setMsg("");
                                    // resetForm({ values: '' });
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    setOtp(false);
                                }, 3000);
                            }
                        },
                        (error) => {
                            const message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setStatus(400);
                            setloading(false);
                            setIsLoading(false);
                            if (error.response.data.status === 400) {
                                setMsg(
                                    error.response.data.Message
                                        ? error.response.data.Message
                                        : error.response.data.Error
                                );
                                if (error.response.data.Message === "Invalid Otp") {
                                    setResendOtp(true);
                                    setcount(count + 1);
                                    setTimeout(() => {
                                        setMsg("");
                                    }, 3000);
                                } else {
                                    setOtp(false);
                                    setMinutes(0);
                                    setSeconds(0);
                                    // resetForm({ values: '' });
                                    setTfaUrl(false);
                                    setTfaEmail("");
                                    setOtpbtn(true);
                                    setDataFromChild(false);
                                    setAmount("");
                                    setReceiver("");
                                    setAdddress("");
                                    setCoin("");
                                    setOtp1("");
                                    setTimeout(() => {
                                        setMsg("");
                                        setOtp(false);
                                    }, 3000);
                                }
                            } else {
                                setMsg(error?.response?.data.Error || message);
                                setloading(false);
                                setIsLoading(false);
                                setTimeout(() => {
                                    setMsg("");
                                    setOtp(false);
                                }, 3000);
                            }
                        }
                    )
                    .catch((error) => {
                        setStatus(400);
                        setMinutes(0);
                        setSeconds(0);
                        setResendOtp(false);
                        setloading(false);
                        setIsLoading(false);
                        // resetForm({ values: '' });
                        setTfaUrl(false);
                        setTfaEmail("");
                        setOtpbtn(true);
                        setDataFromChild(false);
                        setAmount("");
                        setReceiver("");
                        setAdddress("");
                        setCoin("");
                        setOtp1("");
                        setOtp(false);
                        setTimeout(() => {
                            setMsg("");
                        }, 5000);
                        setMsg(`CatchError:${error}${error?.message}`);
                        if (error.message === "Network Error") {
                            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                        } else {
                            setMsg(`CatchError:${error}${error?.message}`);
                        }
                    });
            } catch (error) {
                setStatus(400);
                setloading(false);
                setIsLoading(false);
                // resetForm({ values: '' });
                setTfaUrl(false);
                setTfaEmail("");
                setOtpbtn(true);
                setDataFromChild(false);
                setAmount("");
                setReceiver("");
                setAdddress("");
                setCoin("");
                setOtp1("");
                setOtp(false);
                setTimeout(() => {
                    setMsg("");
                }, 5000);
                if (error.message === "Network Error") {
                    // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                } else {
                    setMsg(`CatchError:${error}${error?.message}`);
                }
            }

        }
    };

    const handleDataFromChild = (data) => {
        // console.log('Child to Parent');
        if (data === true) {
            saveToken();
            setDataFromChild(data);
        }
    };

    function checkIfString(value) {
        const characterPattern = /^[A-Za-z]+$/;

        return characterPattern.test(value);
    }

    const CoinConversion = async (amount, coin) => {
        let coinType = coin;
        if (coinType === null) {
            coinType = externalCoin;
        }


        let coinVal = null;
        if (checkIfString(coinType)) {
            coinVal = options.filter((token) => token.label === coinType);
        } else {
            coinVal = options.filter((token) => token.value === coinType);
        }
        coinType = coinVal[0]?.label;
        setTransactionCoin(coinType);
        setwalletCoin(coinVal[0]?.value);
        setloader(false);
        return;

    };
    const OtpSend = async () => {
        setloading(true);
        return axios
            .post(`${API_URL}direct-transaction-otp`, "", { headers: authHeader() })
            .then((response) => {
                if (response.status === 200) {
                    setStatus(200);
                    setloading(false);
                    setMsg(response.data.message);
                    setTimeout(() => {
                        setMsg("");
                    }, 2000);
                }
            })
            .catch((error) => {
                setStatus(400);
                setloading(false);
                if (error.message === "Network Error") {
                    setMsg(
                        `Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`
                    );
                } else {
                    setMsg(`CatchError:${error}${error?.message}`);
                }
                setTimeout(() => {
                    setMsg("");
                }, 3000);
            });
    };
    const SendOtp = () => {
        setOtp(true);
        OtpSend();
    };
    const ResendOtp = () => {
        setloading(true);
        setOtp(true);
        OtpSend();
        setResendOtp(false);
        setcount(count + 1);
        setMinutes(1);
        setSeconds(59);
    };
    const handleBtcChange = (event) => {
        const inputAddress = event.target.value.trim();
        setBtcAddress(event.target.value);
    };

    useEffect(() => {
        if (btcAddress === "") {
            setValidBtcAddress(null); // Reset validation status when the input is empty
        } else {
            setValidBtcAddress(validate(btcAddress, Network.testnet));
        }
    }, [btcAddress]);


    const handleInputChange = (name) => {
        // debugger;
        setsearchLoad(true);

        if (name.length >= 2) {
            getApprover({ name })
                .then((res) => {
                    setsearchLoad(false);

                    const arr = res.data.user.map((i) => ({
                        id: i._id,
                        address: i.address,
                        value: i.name,
                        label: i.name,
                        sol_address: i?.sol_address,
                        onChainAddress: i?.secondary_wallet_address,
                        onChainSolAddress: i?.secondary_wallet_sol_address,
                        trcWalletAddress: i?.trc_address,
                        striga_user_id: i?.striga_user_id
                    }));

                    setsearchLoad(false);
                    // Case-insensitive comparison
                    const matchingOptions = arr.filter(
                        (option) => option.value.toLowerCase() === name.toLowerCase()
                    );

                    setDefaultBenificiary(matchingOptions[0]?.address)
                    setRecieverSolAddress(matchingOptions[0]?.sol_address)
                    setRecieverTrcAddress(matchingOptions[0]?.trcWalletAddress)
                    setOnchainWalletAddress(matchingOptions[0])
                    setapproverData(matchingOptions);
                })
                .catch((error) => {
                    setsearchLoad(false);
                    setstatusCode(400);

                    if (error.message === "Network Error") {
                        // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                    } else {
                        setMsg(`CatchError:${error}${error?.message}`);
                        toast.error(`CatchError:${error}${error?.message}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                    setTimeout(() => {
                        setMsg("");
                    }, 5000);
                });
        } else {
            setapproverData([]); // Clear options when input length is less than 2
        }

        setsearchLoad(false);
    };

    const fetchExternalWalletBSCList = async () => {
        const payload = {
            wallet_address: account?.address,
        };
        const res = await getUserBalanceExternalWalletBSCList(payload);
        setExternalWalletBSCList(res?.data?.data);
        return;
    };

    const userData = async () => {
        try {
            const res = await getUserInfo()
            setUserData(res?.data?.User)

        } catch (error) {
            console.log("🚀 ~ userInfo ~ error:", error)
        }
    }

    useEffect(() => {
        if (account?.address) {
            fetchExternalWalletBSCList();
        }
        userData()
    }, []);
    const fetchgetFee = async () => {
        setIsLoading(true);
        try {
            const requestData = {
                symbol: coin1,
            };
            const response = await getFee(requestData);
            setWithdrawFee(response.data.data)
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            setWithdrawFee(0)

        }
    };

    useEffect(() => {
        if (amount1 && coin1) {

            fetchgetFee()
        }
    }, [coin1, amount1])


    const fetchSubWallet = async () => {
        setIsLoading(true)
        try {
            const res = await userBalanceInternalWalletSubWallet()
            setCoinList(res?.data?.data)
            // setFilterData(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
        }
    }
    const userDataInfo = JSON.parse(localStorage.getItem("user"));


    useEffect(() => {
        fetchSubWallet()

        // defaultUser = `${userDataInfo?.data?.user?.firstName} ${userDataInfo?.data?.user?.lastName}`
        // handleInputChange(defaultUser)
    }, [])
    useEffect(() => {
        if (userDataInfo) {
            const defaultUser = `${userDataInfo?.data?.user?.firstName} ${userDataInfo?.data?.user?.lastName}`
            handleInputChange(defaultUser)
        }
    }, [selectedVirtualWalletTo])

    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
    };
    const VirtualWalletFromOptions = [
        { label: 'Virtual Wallet', value: 'internal' },
        { label: 'Web3 wallet', value: 'external' },
    ];
    const VirtualWalletToOptions = [
        { label: 'Bank Wallet', value: 'bank' },
        { label: 'Onchain Wallet', value: 'onChain_wallet' },
        { label: 'External Wallet ', value: 'external_wallet' },
        { label: 'Internal CUstodiy ', value: 'internal_custodiy' },
    ];
    const optionChain = [
        { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
    ];


    useEffect(() => {
        if (internalAmount < amount1) {
            toast.error(" Yout don't have enough balance")
        }

    }, [amount1, internalAmount])
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div
                className="col-12 w-100"

            >

                {walletError && (
                    <WalletError className="input feedback">{walletError}</WalletError>
                )}
                {walletSuccess && (
                    <WalletSuccess className="input feedback">
                        {walletSuccess}
                    </WalletSuccess>
                )}
                <Formik
                    initialValues={{
                        receiver: "",
                        amount: "",
                        coin: benficiaryToggle === "external" ? externalCoin : "",
                        otp: "",
                        isRecipientAddress: true,
                    }}
                    onSubmit={(values, { resetForm }) => {


                        if (otp1 === "") {
                            // console.log('==external', isWallet, account.address);
                            if (benficiaryToggle === "external") {
                                if (isWallet) {
                                    SendOtp();
                                } else {
                                    setloading(true);
                                    setStatus(400);
                                    setMsg("Your Wallet is not connected");
                                    setOtp(false);
                                    setloading(false);
                                    setTimeout(() => {
                                        setMsg("");
                                    }, 4000);
                                }
                            } else {
                                SendOtp();
                            }
                        } else {

                            if (user.data.user.TFA === true) {
                                setOtpbtn(false);
                                setTfaUrl(true);
                                setTfaEmail(user.data.user.email);
                            } else {
                                setloading(true);
                                saveToken();
                            }
                        }
                    }}
                    validate={(values) => {
                        const errors = {};
                        const regex = /^[0-9\b.]+$/;
                        const negRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
                        if (!receiver1 && walletOption == "main_wallet") {
                            errors.receiver = "Recipeint Wallet Address is Required";
                        }
                        if (!amount1) {
                            errors.amount = "Amount is Required!!";
                        } else if (
                            ["ETH", "BNB", "MATIC"].includes(
                                coin1 === null ? externalCoin : coin1
                            )
                        ) {
                            if (!negRegex.test(amount1)) {
                                errors.amount = "Amount must be in digit e.g 10,2.3";
                            }
                        } else if (
                            !["ETH", "BNB", "MATIC"].includes(
                                coin1 === null ? externalCoin : coin1
                            )
                        ) {
                            if (!regex.test(amount1)) {
                                errors.amount = `Amount ${coin1 === "" ? "" : `in ${coin1}`
                                    } must be in digit and should be greater then 0 e.g 10`;
                            }
                        }
                        // console.log(values);
                        const coinVal = coin1 === null ? externalCoin : coin1;
                        if (!coinVal) {
                            errors.coin = "coin is Required!!";
                        }
                        if (otp) {
                            const otpRegex = /^[0-9\b]+$/;
                            if (!otp1) {
                                errors.otp = "Otp is Required!!";
                            } else if (!otpRegex.test(otp1)) {
                                errors.otp = "Invalid Otp!! must be in digits";
                            }
                        }
                        return errors;
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Alert
                                    variant={status === 200 ? "success" : "danger"}
                                    show={msg !== ""}
                                    style={{
                                        marginTop: "1rem",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: status === 200 ? "#c1f9c1" : "#f1beb0",
                                        color: status === 200 ? "#22bb33" : "red",
                                    }}
                                    onClose={() => {
                                        setMsg("");
                                    }}
                                    dismissible
                                >
                                    {msg}
                                </Alert>
                                <Alert
                                    variant={
                                        statusCode === 200 || statusCode === "success"
                                            ? "success"
                                            : "danger"
                                    }
                                    show={errorMsg !== undefined}
                                    style={{
                                        marginTop: "1rem",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor:
                                            statusCode === 200 || statusCode === "success"
                                                ? "#c1f9c1"
                                                : "#f1beb0",
                                        color:
                                            statusCode === 200 || statusCode === "success"
                                                ? "#22bb33"
                                                : "red",
                                    }}
                                    onClose={() => {
                                        dispatch(clearMsg());
                                    }}
                                    dismissible
                                >
                                    {errorMsg}
                                </Alert>

                                <div className="row mb-1">

                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                        <SelectDiv style={{ display: "flex", width: "100%" }}>
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                                style={{ fontSize: "small" }}
                                            >
                                                From
                                            </label>
                                            <CustomSelect
                                                value={selectedVirtualWalletFrom}
                                                onChange={(value) => {

                                                    setSelectedVirtualWalletFrom(value.value)
                                                    // setSelectedValue(value.value)
                                                }}
                                                options={
                                                    VirtualWalletFromOptions
                                                }
                                                isSearchable={false}
                                            />
                                        </SelectDiv>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                        <SelectDiv style={{ display: "flex", width: "100%" }}>
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                                style={{ fontSize: "small" }}
                                            >
                                                To
                                            </label>
                                            <CustomSelect
                                                value={selectedVirtualWalletTo}
                                                onChange={(value) => {

                                                    setSelectedVirtualWalletTo(value.value)
                                                }}
                                                options={
                                                    VirtualWalletToOptions
                                                }

                                                isSearchable={false}
                                            />
                                        </SelectDiv>
                                    </div>

                                    {
                                        selectedVirtualWalletFrom == "external" &&
                                        <>
                                            <div className='col-lg-3 col-md-3 col-sm-12 mt-3 align-self-end'>
                                                <WagmiConfig config={wagmiConfig}>
                                                    <Web3ButtonPage />
                                                </WagmiConfig>

                                            </div>
                                            <>
                                                <Web3Modal
                                                    projectId={projectId}
                                                    ethereumClient={ethereumClient}
                                                    themeVariables={{
                                                        '--w3m-accent-color': '#4ce1b6',
                                                        '--w3m-z-index': 127,
                                                        '--w3m-overlay-backdrop-filter': 'blur(6px)',
                                                    }}
                                                />
                                                {/* <WagmiConfig config={wagmiConfig}>
                <WalletResponse chain={ChainValue}  />

              </WagmiConfig> */}
                                            </>
                                        </>
                                    }



                                    {selectedVirtualWalletFrom == "external" &&
                                        <div className=" col-lg-3 col-md-3 col-sm-12 mt-1">


                                            <SelectDiv style={{ display: "flex", width: "100%" }}>
                                                <label
                                                    htmlFor="email"
                                                    className="text-white"
                                                    style={{ fontSize: "small" }}
                                                >
                                                    Select Chain
                                                </label>
                                                <CustomSelect
                                                    name="chain"
                                                    className="w-25"
                                                    value={
                                                        ChainValue
                                                    }
                                                    onChange={(
                                                        value,
                                                    ) => {
                                                        setChainValue(
                                                            value.value,
                                                        );
                                                    }}
                                                    options={
                                                        optionChain
                                                    }
                                                />
                                            </SelectDiv>
                                        </div>
                                    }

                                    {selectedVirtualWalletTo == 'external_wallet' ? (
                                        <div className="col-md-4 col-lg-4 col-sm-12">
                                            <InputDiv>
                                                <Label htmlFor="email" className="mt-3">
                                                    Recipient Address *
                                                </Label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <ChatSearchInput
                                                        name="receiver"
                                                        type="text"
                                                        // disabled
                                                        placeholder="Enter recipient address"
                                                        value={receiver1}
                                                        onChange={(value) => {
                                                            setReceiver(value.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.receiver && touched.receiver && "error"
                                                        }
                                                    />
                                                    {errors.receiver && touched.receiver && (
                                                        <Error className="input feedback">
                                                            {errors.receiver}
                                                        </Error>
                                                    )}
                                                </div>
                                            </InputDiv>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center gap-2 col-md-4 col-lg-4 col-sm-12">
                                                <InputDiv>
                                                    <Label htmlFor="email" className="mt-1">
                                                        Recipient Address *
                                                        <Tooltip
                                                            text="Copy Address"
                                                            placement="top"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <SidebarLinkIcon
                                                                style={{ cursor: "pointer" }}
                                                                className="ml-1 lnr lnr-book"

                                                                onClick={() =>
                                                                    onCopyWalletAddress(
                                                                        selectedVirtualWalletTo === "bank"
                                                                            ? strigaWAlletAddress
                                                                            : selectedVirtualWalletTo === "onChain_wallet"
                                                                                ? ["TRC", "USDT (TRC20)"].includes(coin1) && recieverTrcAddress
                                                                                    ? recieverTrcAddress
                                                                                    : ["LTX", "WEN", "SOLANA", "SoLpy"].includes(coin1)
                                                                                        ? onChainWalletAddress?.onChainSolAddress
                                                                                        : onChainWalletAddress?.onChainAddress
                                                                                : ["TRC", "USDT (TRC20)"].includes(coin1) && recieverTrcAddress
                                                                                    ? recieverTrcAddress
                                                                                    : receiver1 || defaultBenificiary
                                                                    )

                                                                }
                                                            />
                                                        </Tooltip>
                                                    </Label>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <CustomSelect
                                                            isLoading={searchLoad}
                                                            name="receiver"
                                                            onInputChange={handleInputChange}
                                                            placeholder={
                                                                selectedVirtualWalletTo === "bank"
                                                                    ? strigaWAlletAddress
                                                                    : selectedVirtualWalletTo === "onChain_wallet"
                                                                        ? ["TRC", "USDT (TRC20)"].includes(coin1) && recieverTrcAddress
                                                                            ? recieverTrcAddress
                                                                            : ["LTX", "WEN", "SOLANA", "SoLpy"].includes(coin1)
                                                                                ? onChainWalletAddress?.onChainSolAddress
                                                                                : onChainWalletAddress?.onChainAddress
                                                                        : ["TRC", "USDT (TRC20)"].includes(coin1) && recieverTrcAddress
                                                                            ? recieverTrcAddress
                                                                            : receiver1 || defaultBenificiary
                                                            }

                                                            onChange={(value) => {
                                                                setReceiver(value.address);
                                                                setOnchainWalletAddress(value)
                                                                if (selectedVirtualWalletTo == "onChain_wallet" && !value?.onChainAddress) {
                                                                    toast.error("This user don't have Onchain Wallet")
                                                                }
                                                                setRecieverSolAddress(value.sol_address
                                                                );
                                                            }}
                                                            // value={receiver1}
                                                            options={approverData}
                                                            isSearchable
                                                        />{" "}
                                                        {errors.receiver && touched.receiver && (
                                                            <Error className="input feedback">
                                                                {errors.receiver}
                                                            </Error>
                                                        )}
                                                    </div>

                                                </InputDiv>

                                            </div>

                                        </>
                                    )}

                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                        <SelectDiv style={{ display: "flex", width: "100%" }}>
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                                style={{ fontSize: "small" }}
                                            >
                                                Coins *
                                            </label>
                                            {isLoading1 ? (
                                                <div>
                                                    <Spinner style={{ color: colorAccent }} />
                                                </div>
                                            ) : (
                                                <CustomSelect
                                                    value={coin1}
                                                    onChange={(value) => {

                                                        setCoin(value.value);
                                                        setInternalPrice(value?.price);
                                                        setExterCoinBalance(value?.label?.props?.text);
                                                        setExterCoinAmount(value?.price?.priceDta?.price);
                                                        setExterCoinSymbol(value?.price?.priceDta?.symbol);
                                                        setInternalAmount(value?.label?.props?.smallText);
                                                        setAmount("");
                                                    }}
                                                    options={options}
                                                />
                                            )}

                                            {errors.coin && touched.coin && (
                                                <Error className="input feedback">
                                                    {errors.coin}
                                                </Error>
                                            )}
                                        </SelectDiv>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                        <InputDiv>
                                            <label htmlFor="email" className="text-white mb-3">
                                                Amount *
                                            </label>
                                            <ChatSearchInput
                                                name="amount"
                                                type="text"
                                                placeholder="Enter your amount"
                                                value={amount1}
                                                onChange={(value) => {
                                                    // console.log('asas', value);
                                                    setAmount(value.target.value);
                                                    if (coin1 !== "") {
                                                        return CoinConversion(value.target.value, coin1);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                className={`form-control w-100 ${errors.amount && touched.amount && "error"
                                                    }`}
                                            />
                                            {errors.amount && touched.amount && (
                                                <Error className="input feedback">
                                                    {errors.amount}
                                                </Error>
                                            )}
                                        </InputDiv>
                                    </div>
                                    {isLoading1 ? (
                                        ""
                                    ) : (
                                        <div className="col-lg-1 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <FormButton
                                                    variant="outline-white"
                                                    className="py-2 mt-4 w-100 btn-sm"
                                                    onClick={fetchMaxBalance}
                                                >
                                                    {loading ? "Loading...." : "Max"}
                                                </FormButton>
                                                {errors.amount && touched.amount && (
                                                    <Error className="input feedback">
                                                        {errors.amount}
                                                    </Error>
                                                )}
                                            </InputDiv>
                                        </div>
                                    )}
                                </div>

                                <div v className="row mb-1">
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                                        {load && benficiaryToggle !== "external" ? (
                                            <span
                                                style={{
                                                    color: "#4ce1b6",
                                                    fontSize: "14px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                Loading.....
                                            </span>
                                        ) : coin1 || externalCoin ? (
                                            <span>
                                                <InputDiv>
                                                    <label htmlFor="email" className="text-white mt-2">
                                                        Balance
                                                    </label>
                                                    <ChatSearchInput
                                                        type="text"
                                                        disabled
                                                        value={
                                                            benficiaryToggle === "external"
                                                                ? `${numeral(externalCoinAmount * amount1).format('0,0.00000')} $`
                                                                : `${numeral(internalPrice * amount1).format('0,0.00000')} $`
                                                        }
                                                        className="form-control w-100 bg-transparent mb-3"
                                                    />
                                                </InputDiv>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {withdrawFee?.fee >= 0 && (
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <label
                                                    htmlFor="email"
                                                    className="text-white mt-2"
                                                    style={{ fontSize: "small" }}
                                                >
                                                    withdrawFee
                                                </label>
                                                <div>
                                                    <ChatSearchInput
                                                        name="otp"
                                                        placeholder="withdrawFee"
                                                        value={`${withdrawFee?.percentage > 0
                                                            ? (amount1 / 100 * withdrawFee.percentage).toFixed(5)
                                                            : Number(withdrawFee?.fee || 0).toFixed(5)
                                                            } ${withdrawFee?.symbol || coin1}`}
                                                        className={`form-control w-100 `}

                                                    />

                                                </div>
                                            </InputDiv>
                                        </div>
                                    )}
                                    {otp && (
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <label
                                                    htmlFor="email"
                                                    className="text-white mt-2"
                                                    style={{ fontSize: "small" }}
                                                >
                                                    OTP *
                                                </label>
                                                <div>
                                                    <ChatSearchInput
                                                        name="otp"
                                                        type="text"
                                                        placeholder="Enter your otp"
                                                        value={otp1}
                                                        // onChange={handleChange}
                                                        onChange={(e) => setOtp1(e.target.value)}
                                                        onBlur={handleBlur}
                                                        className={`form-control w-100 ${errors.otp && touched.otp && "error"
                                                            }`}
                                                    />
                                                    {errors.otp && touched.otp && (
                                                        <Error className="input feedback">
                                                            {errors.otp}
                                                        </Error>
                                                    )}
                                                </div>
                                            </InputDiv>
                                        </div>
                                    )}

                                    {otp && userSessionData.data.user.TFA && (
                                        <div className=" col-lg-3 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <label htmlFor="email" className="text-white mb-3">
                                                    Verify TFA *
                                                </label>
                                                <ChatSearchInput
                                                    name="tfa"
                                                    type="text"
                                                    placeholder="Enter Code"
                                                    value={tfaCode}
                                                    onChange={(e) => {
                                                        setTfaCode(e.target.value);
                                                    }}
                                                />

                                            </InputDiv>

                                            <InputDiv>
                                                <FormButton
                                                    variant="outline-white"
                                                    className="py-2 mt-4 w-100 btn-sm"
                                                    onClick={handleTfaCode}
                                                    disabled={isTfaVarified}
                                                >
                                                    {isTfaVarified ? "Verified" : "Verify"}
                                                </FormButton>

                                            </InputDiv>

                                        </div>
                                    )}
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-1 mx-auto">
                                        {loading}
                                        {otpbtn && (
                                            <FormButton
                                                type="submit"
                                                variant="outline-success"
                                                className="py-2 mt-5 w-100"
                                                disabled={otp && otp1 === "" || btnDisable || (otp1 && userSessionData.data.user.TFA && !isTfaVarified) || (externalCoinAmount * amount1 <= 0 || internalPrice * amount1 <= 0) || internalAmount < amount1}
                                            >
                                                {loading ? "Loading...." : "Send"}
                                            </FormButton>
                                        )}
                                        {otpbtn &&
                                            ((seconds > 0 || minutes > 0) && count < 3 ? (
                                                <span style={{ color: colorAccent }}>
                                                    Time Remaining:{" "}
                                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                                    {seconds < 10 ? `0${seconds}` : seconds}
                                                </span>
                                            ) : null)}
                                        {otpbtn && resendOtp && count < 4 && (
                                            <FormButton
                                                // type="submit"
                                                variant="primary-outline"
                                                className="py-2 mt-2 w-100"
                                                disabled={seconds > 0 || minutes > 0}
                                                onClick={() => {
                                                    ResendOtp();
                                                }}
                                            >
                                                Resend Otp
                                            </FormButton>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-4 col-lg-4 col-sm-12 mx-auto">
                                        <Tfaaa
                                            tfaEmail={tfaEmail}
                                            tfaUrl={tfaUrl}
                                            onDataFromChild={handleDataFromChild}
                                        />
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
                <SimpleModal isActive={IsBtc} onClose={() => setIsBtc(false)}>
                    <InputDiv>
                        <Grid>
                            <Label htmlFor="email">Add BTC wallet Address</Label>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Enter your address"
                                    value={btcAddress}
                                    onChange={handleBtcChange}
                                />
                                {validBtcAddress === false && (
                                    <Error className="input feedback">
                                        {validBtcAddress === false
                                            ? "Invalid BTC Address"
                                            : "Valid BTC Address"}
                                    </Error>
                                )}
                            </div>
                        </Grid>
                    </InputDiv>
                    <InputDiv>
                        <Grid>
                            <Label />
                            {validBtcAddress ? (
                                <Button
                                    className="w-25"
                                    onClick={() => {
                                        fetchBalance();
                                    }}
                                >
                                    Submit
                                </Button>
                            ) : (
                                <Button className="w-25" disabled>
                                    Submit
                                </Button>
                            )}
                        </Grid>
                    </InputDiv>
                </SimpleModal>

            </div>
        </>
    );
};

export default VirtualWalletSendForm;
const Label = styled.label`
        @media screen and (max-width: 876px) {
          margin - bottom: 0px;
        margin-top: 8px;
}
        `;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 15rem 20rem 20rem;
  grid-gap: 1rem;
  width: 100%;
  @media screen and (max-width: 876px) {
    grid-template: none;
  }
`;
const AmountDiv = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;
const WalletError = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 16px;
  cursor: none;
`;
const WalletSuccess = styled.div`
  color: #4ce1b6;
  padding: 2px 0px;
  font-size: 16px;
  cursor: none;
`;
const SelectDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  font-size: 14px;
`;

const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
